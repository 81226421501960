import Vue from 'vue'
import VueRouter from 'vue-router'
import FrontPage from '../views/FrontPage.vue'

Vue.use(VueRouter) 

const routes = [
  {
    path: '/',
    name: 'FrontPage',
    component: FrontPage
  },
  {
    path: '/onama',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/servis',
    name: 'Service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/:categoryId',
    name: 'ProductList',
    component: () => import('../views/ProductList.vue'),
    params: true,
  },
  {
    path: '/:categoryId/:articleId',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
