import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        categories: [
            {
                naziv: 'Toplovodni perači',
                ctg_id: 'toplovodni_peraci',
            },
            {
                naziv: 'Hladnovodni perači',
                ctg_id: 'hladnovodni_peraci',
            },
            {
                naziv: 'Perači podova',
                ctg_id: 'peraci_podova',
            },
            {
                naziv: 'Motorne pile',
                ctg_id: 'motorne_pile',
            },
            {
                naziv: 'Kosilice',
                ctg_id: 'kosilice',
            },
            {
                naziv: 'Usisavači',
                ctg_id: 'usisavaci',
            },
            {
                naziv: 'Pribor',
                ctg_id: 'pribor',
            },
            {
                naziv: 'Usluge',
                ctg_id: 'usluge',
            },   
        ],
        // currentCategory: '',
        // currentArticle: '',
        brands: [
            'FRANK',
            'STIHL',
            'KRÄNZLE',
            'KÄRCHER',
            'NILFISK'
        ],
        pictures: [ require('@/assets/pictures/toplovodni_peraci/naslovna-toplovodni.jpg'),
            require('@/assets/pictures/hladnovodni_peraci/naslovna-hladnovodni.jpg'),
            require('@/assets/pictures/peraci_podova/naslovna-peraci-podova.jpg'),
            require('@/assets/pictures/motorne_pile/naslovna-pila.jpg'),
            require('@/assets/pictures/flakserice/naslovna-kosilica.jpg'),
            require('@/assets/pictures/usisavaci/naslovna-usisavaci.jpg'),
            require('@/assets/pictures/pribor/naslovna-pribor.jpg'),
            require('@/assets/pictures/usluge/naslovna-usluge.jpg'),
        ],
        articles: [ 
//----------------------------------------MOTORNE PILE -------------------------------------------------
            {   
                naziv: 'Benzinska motorna pila MS 170',
                id: 1,
                kategorija: 'Motorne pile',
                picture: require('@/assets/pictures/motorne_pile/motorna-pila-ms-170.jpg'),
                brand: 'STIHL',
                opis: 'Praktična benzinska motorna pila za izradu ogrjeva. Jednostavno upravljanje pomoću višefunkcijske upravljačke ručke s pristupačnim komandama. Ulazni model za privatne korisnike i obrtnike.',
                detalji: {
                    'OBUJAM (CM3)': '30.1',
                    'SNAGA KW / KS': '1,2 / 1,6',
                    'TEŽINA KG': '4.1'
                }
            },
            { 
                naziv: 'Benzinska motorna pila MS 171', 
                id: 2,
                kategorija: 'Motorne pile',
                picture: require('@/assets/pictures/motorne_pile/motorna-pila-ms-171.jpg'),
                brand: 'STIHL',
                opis: 'Dugi vremenski interval između servisa zbog sustava prozračivanja zraka otpornog na onečišćenja. Lagana benzinska motorna pila za izradu ogrjeva i održavanje okoliša. Za privatne korisnike i obrtnike. Postrano napinjanje lanca.',
                detalji: {
                    'OBUJAM (CM3)': '31.8',
                    'SNAGA KW / KS': '1,3 / 1,8',
                    'TEŽINA KG': '4.3'
                }
            },
            { 
                naziv: 'Benzinska motorna pila MS 180',
                id: 3,
                kategorija: 'Motorne pile',
                picture: require('@/assets/pictures/motorne_pile/motorna-pila-ms-180.jpg'),
                brand: 'STIHL',
                opis: 'Ulazni model za privatne korisnike i obrtnike. Snažna benzinska motorna pila za izradu ogrjeva. Postrano napinjanje lanca.',
                detalji: {
                    'OBUJAM (CM3)': '31.8',
                    'SNAGA KW / KS': '1,4 / 1,9',
                    'TEŽINA KG': '4.1'
                }
            },
            { 
                naziv: 'Benzinska motorna pila MS 231 C-BE',
                id: 4,
                kategorija: 'Motorne pile',
                picture: require('@/assets/pictures/motorne_pile/motorna-pila-ms-231-c-be.jpg'),
                brand: 'STIHL',
                opis: 'Pokretanje uređaja olakšano pumpicom za dobavu goriva do rasplinjača i ručkom za povlačenje užeta za startanje ErgoStart. Bezalatno brzo postrano napinjanje lanca. Zatvarači spremnika otvaraju se bez potrebe za dodatnim alatom, što pojednostavnjuje punjenje gorivom i mazivom. Snažna benzinska motorna pila za izradu ogrjeva. Za privatne korisnike i obrtnike.',
                detalji: {
                    'OBUJAM (CM3)': '42.6',
                    'SNAGA KW / KS': '2 / 2.7',
                    'TEŽINA KG': '5.1'
                }
            },
//----------------------------------------FLAKSERICE -------------------------------------------------
            { 
                naziv: 'Lagana motorna kosa FS 38',
                id: 5,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/motorna-kosilica-fs-38.jpg'),
                brand: 'STIHL',
                opis: 'Lagani ulazni model trimera za vrtlare. Iznimno jednostavno uvođenje rezne najlonske niti u glavu za košnju. Koristi se za trimanje trave i održavanje stuba i staza urednima. Okrugla ručka omogućava košnju u malom manevarskom prostoru.',
                detalji: {
                    'OBUJAM (CM3)': '27.2',
                    'SNAGA KW / KS': '0,65 / 0,9',
                    'TEŽINA KG': '4.2'
                }
            },
            { 
                naziv: 'Lagana motorna kosa FS 40',
                id: 6,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/motorna-kosilica-fs-40.jpg'),
                brand: 'STIHL',
                opis: 'Idealna za košnju malih travnatih površina. Odmah spremna za ponovni rad zahvaljujući tipki za zaustavljanje. Lagani ulazni model trimera za vrtlare. Snažna i jednostavna za korištenje. Iznimno jednostavno uvođenje rezne najlonske niti u glavu za košnju.',
                detalji: {
                    'OBUJAM (CM3)': '27.2',
                    'SNAGA KW / KS': '0,7 / 1,0',
                    'TEŽINA KG': '4.4'
                }
            },
            { 
                naziv: 'Lagana motorna kosa FS 50',
                id: 7,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/motorna-kosilica-fs-50.jpg'),
                brand: 'STIHL',
                opis: 'Snažna i jednostavna za korištenje. Okrugla ručka podesiva bez potrebe za dodatnim alatom. Lagani ulazni model trimera za vrtlare. Iznimno jednostavno uvođenje rezne najlonske niti u glavu za košnju. Učinkovita pri trimanju trave i čišćenju oko stabala.',
                detalji: {
                    'OBUJAM (CM3)': '27.2',
                    'SNAGA KW / KS': '0,8 / 1,1',
                    'TEŽINA KG': '4.4'
                }
            },
            { 
                naziv: 'Lagana motorna kosa FS 55 R',
                id: 8,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/motorna-kosilica-fs-55-r.jpg'),
                brand: 'STIHL',
                opis: 'Okrugla ručka omogućava košnju u malom manevarskom prostoru. Svestrani trimer za vrtlare željne velikom učinku stroja. Jednostrani uprtni remen olakšava rad. Laka za korištenje pomoću multifunkcijske ručke.',
                detalji: {
                    'OBUJAM (CM3)': '27.2',
                    'SNAGA KW / KS': '0,75 / 1,0',
                    'TEŽINA KG': '4.4'
                }
            },
            { 
                naziv: 'Benzinska kosilica RM 248',
                id: 9,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/benzinska-kosilica-rm-248.jpg'),
                brand: 'STIHL',
                opis: 'STIHL motorna kosilica RM 248 razvijena je za jednostavno i lako održavanje malih do srednjih travnatih površina. Ovaj praktični ulazni model kosilice posebno je okretan i jednostavan za uporabu. S ovom kosilicom možete opušteno kositi travu svoje okućnice bez prevelikog utroška energije. Tome između ostaloga služi preklopna upravljačka ručka oklopljena posebnom Super-Softgriff ručkom za udobno i ergonomski pogodno upravljanje uređajem. Jednostavno sedmostupanjsko podešavanje visine košnje omogućava košnju trave u rasponu od 25 do 75 mm iznimno finog i preciznog reza. Velika zapremnina kožare za otkos od 55 litara omogućava dugotrajan rad bez potrebe za brojnim pauzama.',
                detalji: {
                    'OBUJAM (CM3)': '139',
                    'SNAGA KW / PS': '2,1 / 2,8',
                    'TEŽINA KG': '26'
                }
            },
            { 
                naziv: 'Benzinska kosilica RM 545 V',
                id: 10,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/benzinska-kosilica-rm-545-v.jpg'),
                brand: 'STIHL',
                opis: 'Snažni motor opremljen ReadyStart sustavom pokretanja ugrađen na lako i robusno kućište od čvrstog i elastičnog polimera. Vario prijenos omogućava postupno ubrzavanje, čime se štedi pogonski sustav kosilice, a i povećava udobnost rada. Upravljačka ručka kosilice podesiva je po visini u tri položaja. Sedam stupanjski sustav podešavanja visine košnje omogućava precizno šišanje travnjaka.Izvrstan radni učinak pri košnji travnjaka do 1200 četvornih metara, uz mogućnost dogradnje seta za mljevenje otkosa. Opremljena velikom košarom za travu zapremnine 60 litara.',
                detalji: {
                    'OBUJAM (CM3)': '163',
                    'SNAGA KW / PS': '2,4 / 3,3',
                    'TEŽINA KG': '29'
                }
            },
            { 
                naziv: 'Benzinska kosilica RM 655 VS',
                id: 11,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/benzinska-kosilica-rm-655-vs.jpg'),
                brand: 'STIHL',
                opis: 'Benzinska kosilica s kvačilom noža s integriranom kočnicom STIHL RM 655 VS pravi je paket snage i dugovječnosti, i to ne samo zbog svog izuzetno trajnog aluminijskog kućišta.Opremljena je Vario-prijenosom, a širina košnje koju izvodi je impozantnih 53 cm, što ju čini predodređenom za košnju velikih površina. Centralno sedmerostupanjsko podešavanje visine košnje nudi vam na izbor visine od 25 do 85 mm. S ciljem olakšanja početka rada s kosilicom, ovaj uređaj opremljen je funkcijom ReadyStart, koja osigurava pokretanje kosilice već pri prvom pokušaju. Tijekom košnje broj okretaja ovog snažnog motora ostaje konstantan, bez obzira je li došlo do promjene tempa hoda uređaja. Jednostrana komforna upravljačka ručka znatno olakšava upravljanje, kao i ostale poslove oko uređaja kao npr. skidanje i pražnjenje košare za otkos. Posebna značajka ovog uređaja je kvačilo noža koje odvaja vrtnju radilice od rada kosišta, što znači da pri pauzama u radu i naknadnim pokretanjem kosilice motor nije povezan s nožem. Košara za travu zavidne zapremnine od 70 litara, i opremljena pokazivačem napunjenosti košare, vrlo se lako skida i još lakše prazni. Ovaj stroj uistinu je pravi i učinkoviti pratioc pri poslovima na okućnici.',
                detalji: {
                    'OBUJAM (CM3)': '161',
                    'SNAGA KW / PS': '2,5 / 3,4',
                    'TEŽINA KG': '49'
                }
            },
            { 
                naziv: 'Električna kosilica RME 235',
                id: 12,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/električna-kosilica-rme-235.jpg'),
                brand: 'STIHL',
                opis: 'Ako trebate kositi male travnate površine na mjestima osjetljivima na buku, tada je tiha i okretna električna kosilica STIHL RME 235 vaš pravi izbor. Ova je kosilica iznimno okretna i spretna za korištenje, zahvaljujući svojoj laganoj ali robusnoj konstrukciji. Visina košnje na ovom uređaju podesiva je na pet visina, vrlo lako i pomoću jedne poluge. Na taj se način postiže vrlo ujednačen učinak košnje, a time i lije izgled travnjaka na okućnici. Košara za prikupljanje otkosa opremljena je indikatorom napunjenosti, koji će vam na vrijeme naznačiti pravo vrijeme za pražnjenje košare. Sklopiva upravljačka ručka podesiva je po visini s obzirom na stas korisnika, a sam uređaj lako je prenosiv pomoću integrirane prijenosne ručke. Sklapanjem upravljačke ručke postiže se reduciranje dimenzija uređaja, što omogućuje lako i prostorno nezahtjevno skladištenje i transport.',
                detalji: {
                    'SNAGA W': '1.200',
                    'TEŽINA KG': '13',
                    'ZA TRAVNJAKE': 'do 300 m2'
                }
            },
            { 
                naziv: 'Električna kosilica RME 443',
                id: 13,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/električna-kosilica-rme-443.jpg'),
                brand: 'STIHL',
                opis: 'STIHL električna kosilica RME 443 izvrsno obavlja zadaće košnje i uređivanja travnjaka, no ujedno je korisna i za okolinu. Tu se ponajprije misli na vaše susjede, koje veseli činjenica da je vaša kosilica tako tiha. Ovaj kompaktni stroj opremljen je izvrsnim setom kotača, tako da je upravljanje kosilicom lako, a zauzeti smjer vožnje stalan. Osigurač kabla integriran u stroj sprječava neželjeno izvlačenje utikača uređaja iz utičnice produžnog kabla. Visina košnje podešava se centralno i moguće je odabrati neku od sedam visina košnje. Sklopiva košara za otkos zapremnine 55 litara napravljena je od visokokvalitetnog polimernog materijala i znatno olakšava poslove košnje. Način na koji je sazdana skidanje i pražnjenje čini iznimno lakima, a opremljena je i pokazivačem napunjenosti otkosom. Košara je opremljena skupom usmjerivačkih lamela, koje usmjeravaju struju zraka iz kosilice prema donjoj strani košare. Na taj se način čestice otkosa i uskovitlana prašina zadržavaju u otkosu i ne prljaju poslužioca. Upravljačka ručka lako se i brzo otpušta pomoću Quick-Fix steznih kopči, i na taj se način kosilica brzo i jednostavno sklapa za skladištenje i transport. Želite li vlagu i mineralne tvari iz pokošene trave vratiti tlu, možete ovu kosilicu opremiti dodatnim Kitom za usitnjavanje otkosa (malčiranje). Taj pribor omogućava sjeckanje vlati trave u sitne segmente koji padaju na tlo između vlati travnjaka i štite ga od isušivanja.',
                detalji: {
                    'SNAGA W': '1.500',
                    'TEŽINA KG': '20',
                    'ZA TRAVNJAKE': 'do 600 m2'
                }
            },
            { 
                naziv: 'Akumulatorska kosilica RMA 443',
                id: 14,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/akumulatorska-kosilica-rma-443.jpg'),
                brand: 'STIHL',
                opis: 'Postavljate li visoke zahtjeve pred mobilnost vaše kosilice, STIHL RMA 443 je pametan odabir. Opremljena visokokvalitetnim Litij-ionskim akumulatorom iz familije PRO STIHL akumulatorskih baterija, ova kosilica ima velike rezerve energije. Kako bi ovaj uređaj zadovoljavao i aspekt ekonomičnosti, ova kosilica pokretana je pogonskim uređajem opremljenim Eco načinom rada. Nož za košnju posebno je formiran kako bi stvarao minimalan otpor zraka pri radu, pa i s te strane štedi energiju i čini kosilicu upotrebljivom i za košnju većih površina. Sklopiva košara za otkos velike je zapremnine, i ispunjava se otkosom do u potpunosti, a posebno se lako i jednostavno prazni. Zbog iznimne lakoće upravljanja za rukovanje kosilicom troši se vrlo malo snage. Stoj je vrlo jednostavno održavati, a zahvaljujući Quick-Fix brzim zatezačima ručke brzo se sklapa radi lakšeg transporta i skladištenja.',
                detalji: {
                    'ZAPREMNINA KOŠARE L': '55',
                    'TEŽINA KG': '20',
                    'RAZINA ZVUČNE SNAGE LWAD': '92 dB(A)'
                }
            },
            { 
                naziv: 'Akumulatorska kosilica RMA 339 C',
                id: 15,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/akumulatorska-kosilica-rma-339-c.jpg'),
                brand: 'STIHL',
                opis: 'Ova kosilica uobličena je kako bi vam pružila više slobode pri radovima na okućnici. Lagana je, nije ograničena produžnim kablom, a paket snage kojim je opremljena dostaje za košnju travnjaka malih i srednjih površina. Ova kosilica pogonjena je inovativnim STIHL Litij-ionskim akumulatorom serije COMPACT.  Učinkovite Litij-ionske ćelije ovog akumulatora besprijekorno će poslužiti za napajanje i ostalih kompatibilnih STIHL Aku-uređaja. Ovaj uređaj opremljen je Eco-modus načinom rada, što ga čini još štedljivijim pri potrošnji pokretačke energije. Eco-modus način rada je sistem mjerenja energije potrebne za rad, pa pri manjem opterećenju motora smanjuje broj okretaja s ciljem štednje akumulatora. Nož za košnju također je optimiziran za maksimalnu efikasnost, budući da je formiran tako da proizvodi struju zraka kojom odiže travu od tla s ciljem optimalnog učinka košnje i za ravnomjernu košnju.  Sve ovo su činioci koji povećavaju učinak košnje uz smanjenje nepotrebnog utroška energije. Ostale osobine uređaja, kao što su npr. ergonomski oblikovana komforna jednostrana upravljačka ručka, sklopiva košara za otkos, te centralno podešavanje visine košnje čine rad s ovom kosilicom još jednostavnijim i lakšim. Kosilica STIHL RMA 339 C idealan je uređaj za vrtlare koji žele inovativan i okretan uređaj uz optimum radnog učinka. Dolazi u kompletu s  akumulatorom AK 20 i punjačem AL 101.',
                detalji: {
                    'ZAPREMNINA KOŠARE L': '40',
                    'TEŽINA KG': '16',
                    'ŠIRINA KOŠNJE CM': '37'
                }
            },
            { 
                naziv: 'Traktorska kosilica RT 4082',
                id: 16,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/traktorska-kosilica-rt-4082.jpg'),
                brand: 'STIHL',
                opis: 'Ova kompaktna i okretna traktorska kosilica posebno je prikladna za košnju travnjaka okućnice na kojoj je potrebno zaobilaziti mnoštvo prepreka kao što su ukrasno grmlje ili stabla. To ovaj stroj može obaviti bez problema zahvaljujući iznimno malom zaokretnom krugu odn. velikom kutu kojeg kotači mogu postići u odnosu na pravac kosilice. Vozačko sjedalo moguće je podesiti po više osi i na taj ga način prilagoditi stasu svakog vozača. Podni nivo ove kosilice nisko je nad tlom, tako da je iznimno lako popeti se u vozačku poziciju. Upravljanje hodom kosilice obavlja se pritiskom na samo jednu papučicu, nagib koje određuje giba li se kosilica prema naprijed ili unatrag. Praktična i lagana poluga na upravljačkoj ploči zadužena je za aktiviranje sustava za košnju. Uređaj je opremljen košarom za otkos zapremnine 250 litara, a čije je pražnjenje moguće izvesti lako i bez napuštanja vozačkog sjedišta zahvaljujući anatomski oblikovanoj poluzi za zakretanje košare. Sustav prikupljanja otkosa omogućava iznimno precizno i učinkovito uklanjanje trave s pokošene plohe. Po želji moguće je kosilicu opremiti dodatnim setom za usitnjavanje otkosa (malčiranje). Tim procesom vraćamo tlu travnjaka vrijedne mineralne tvari i značajan dio vlage, budući da usitnjeni otkos ostaje na tlu travnjaka, i tako dobrim dijelom čuva tlo od iscrpljivanja i isušivanja.',
                detalji: {
                    'OBUJAM MOTORA CM3': '452',
                    'TEŽINA KG': '202',
                    'SNAGA KW / PS': '7,6 / 10,3',
                }
            },
            { 
                naziv: 'Traktorska kosilica RT 5097 C',
                id: 17,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/traktorska-kosilica-rt-5097-c.jpg'),
                brand: 'STIHL',
                opis: 'Učinkovita traktorska kosilica STIHL RT 5097 namijenjena je njezi i uređivanju travnjaka velikih površina. Velika udobnost korištenja i pregledni i dostupni upravljački elementi čine ovu kosilicu uređajem koji obiluje dobro promišljenim značajkama s ciljem dugotrajnog korištenja bez zamora. Udobno i amortizirajuće vozačko sjedalo može se prilagoditi stasu svakog vozača bez potrebe za dodatnim alatom. Ovu kosilicu karakterizira visoki radni učinak i ravnomjernost pri košnji zahvaljujući snažnom pogonskom uređaju, jednostavnom centralnom podešavanju visine košnje, te brzom i učinkovitom pokretanju i zaustavljanju sustava kosišta pomoću elektromagnetskog kvačila. S obiju strana kosišta nalaze se usmjerivači trave koji jamče stalnu širinu košnje i precizno navođenje kosišta u travi. Vožnja ove traktorske kosilice znatno je pojednostavljena sistemom kontrole kretanja pomoću samo jedne nožne pedale.  Nagibom pedale bira se smjer vožnje stroja, kao i brzina kojom se kosilica kreće. Zahvaljujući praktičnoj savijenoj poluzi za pražnjenje košare za otkos, ona se lako i u potpunosti prazni, i to bez potrebe za silaženjem s upravljačkog sjedala. Košara za otkos sazdana je od posebno otpornog polimernog materijala, a njen dizajn omogućuje lako i temeljito čišćenje i održavanje.',
                detalji: {
                    'OBUJAM MOTORA CM3': '500',
                    'TEŽINA KG': '231',
                    'SNAGA KW / PS': '8,2 / 11,1',
                }
            },
            { 
                naziv: 'Traktorska kosilica RT 6112 C',
                id: 18,
                kategorija: 'Kosilice',
                picture: require('@/assets/pictures/flakserice/traktorska-kosilica-rt-6112-c.jpg'),
                brand: 'STIHL',
                opis: 'Traktorska kosilica STIHL RT 6112 C u svakom je pogledu superiorna i uvjerljiva svojim radnim učinkom velike brzine i preciznosti pri košnji travnjaka velike površine i pri teškim radnim uvjetima. Velika širina košnje zaslužna je za ujednačen i ravnomjeran učinak, kao i Synchron izvedba kosišta. Intuitivan sustav podešavanja visine košnje i lagana i pregledna upravljivost komandama iz ergonomski uobličenog kokpita također doprinose visokoj kvaliteti radnog učinka ovog stroja. Zahvaljujući LCD zaslonu visokog kontrasta, sve funkcije stroja vidljive su pri jednom pogledu i vozač ove kosilice ima punu kontrolu nad svakom fazom rada i korištenja ovog robusnog i učinkovitog stroja.',
                detalji: {
                    'OBUJAM MOTORA CM3': '500',
                    'TEŽINA KG': '247',
                    'SNAGA KW / PS': '8,7 / 11,8',
                }
            },
//----------------------------------------TOPLOVODNI PERAČI -------------------------------------------------
            { 
                naziv: 'Visokotlačni strojevi za čišćenje ',
                id: 19,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-frank.jpg'),
                brand: 'FRANK',
                opis: 'Nudimo Vam bogat program snažnih toplovodnih visokotlačnih strojeva za čišćenje, za profesionalnu upotrebu. FRANK strojevi za pranje s grijanjem vode, s pogonom na monofaznu ili trofaznu struju.',
                detalji: {
                    'RADNI TLAK (bar)': '80 - 240',
                    'SNAGA KW': '2,9 - 7,5',
                    'MAX PROTOK VODE l / h': '700 - 1400',
                    'MAX TEMPERATURA VODE °C': '70 - 150'
                }
            },
            { 
                naziv: 'Visokotlačni perač MH 5M-210/1100FA 400V',
                id: 20,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-mh.jpg'),
                brand: 'NILFISK',
                opis: 'MH 5M linija više srednje klase toplovodnih visokotlačnih perača namijenjena je za zahtjevne dnevne zadatke čišćenja (5-6 sati dnevno) te pruža visoku učinkovitost čišćenja uz niske troškove vlasništva kroz smanjenu potrošnju goriva i optimalni servisni koncept. Inovativni dizajn sa 4 velika kotača omogućava prevoženje uređaja i po neravnim podlogama. Certificirana najniža potrošnja goriva za grijanje vode donosi velike uštede u ukupnom trošku vlasništva uređaja. Opcionalno, uređaji mogu biti opremljeni i koloturom za crijevo duljine 15m.',
                detalji: {
                    'RADNI TLAK (bar)': '210',
                    'SNAGA KW': '7,9',
                    'TEŽINA KG': '174',
                    'MAX PROTOK VODE l / h': '1100',
                    'MAX TEMPERATURA VODE °C': '80 - 150'
                }
            },
            { 
                naziv: 'Visokotlačni perač MH 4M-200/960FA 400V',
                id: 21,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-mh-4m.jpg'),
                brand: 'NILFISK',
                opis: 'MH 4M linija srednje klase toplovodnih visokotlačnih perača namijenjena je za generalne i srednje zahtjevne dnevne zadatke čišćenja 4-5 h dnevno. Inovativni dizajn sa 4 velika kotača omogućava prevoženje uređaja i po neravnim podlogama. Certificirana najniža potrošnja goriva za grijanje vode donosi velike uštede u ukupnom trošku vlasništva uređaja. Opcionalno, uređaji mogu biti opremljeni i koloturom za crijevo duljine 15m.',
                detalji: {
                    'RADNI TLAK (bar)': '200',
                    'SNAGA KW': '5,9',
                    'TEŽINA KG': '133',
                    'MAX PROTOK VODE l / h': '960',
                    'MAX TEMPERATURA VODE °C': '80 - 150'
                }
            },
            { 
                naziv: 'Visokotlačni perač Therm C 11/130',
                id: 22,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-therm-C.jpg'),
                brand: 'KRÄNZLE',
                opis: 'Therm C serija uključuje šest modela visokotlačnih uređaja za pranje na toplu vodu namijenjenih profesionalnim korisnicima koji trebaju ozbiljan toplovodni uređaj za pranje kompaktnih dimenzija koji ne zauzima puno mjesta. Uređaji dolaze u izvedbi sa i bez koluta za namotavanje visokotlačnog crijeva. Imaju sporohodne motore (1400 Rpm) i namijenjeni su dugotrajnoj, svakodnevnoj uporabi u poljoprivredi, industriji, autopraonicama, prijevozničkim tvrtkama, turizmu i sl. Therm C serija posebno se ističe vrhunskim sigurnosnim karakteristikama. Total-stop system gasi elektro-motor i visokotlačnu pumpu uređaja u trenutku kada se pištolj otpusti. Kada se pištolj opet pritisne elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Na ovaj način motor i pumpa rade samo onda kada se uređaj stvarno koristi. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval. Integrirani spremnik vode s plovkom uz Total-stop system osigurava maksimalnu zaštitu uređaja od problema s nedostatkom vode. Spremnik vode veličine je 4 l.',
                detalji: {
                    'RADNI TLAK (bar)': '30 – 130',
                    'SNAGA KW': '2.3 - 3.4',
                    'TEŽINA KG': '150 (s kolutom: 179)',
                    'MAX PROTOK VODE l / h': '660',
                    'MAX TEMPERATURA VODE °C': '140'
                }
            },
            { 
                naziv: 'Visokotlačni perač Therm CA 12/150',
                id: 23,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-therm-ca.jpg'),
                brand: 'KRÄNZLE',
                opis: 'Therm CA serija uključuje šest modela visokotlačnih uređaja za pranje na toplu vodu koji predstavljaju najpovoljniju opciju za profesionalne korisnike koji trebaju ozbiljan toplovodni uređaj za pranje. Uređaji dolaze u izvedbi sa i bez koluta za namotavanje visokotlačnog crijeva. Imaju sporohodne motore (1400 Rpm) i namijenjeni su dugotrajnoj, svakodnevnoj uporabi u poljoprivredi, industriji, autopraonicama, prijevozničkim tvrtkama, turizmu i sl. Total-stop system gasi elektro-motor i visokotlačnu pumpu uređaja u trenutku kada se pištolj otpusti. Kada se pištolj opet pritisne, elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Na ovaj način motor i pumpa rade samo onda kada se uređaj stvarno koristi. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval. Integrirani spremnik vode s plovkom uz Total-stop system osigurava maksimalnu zaštitu uređaja od problema s nedostatkom vode. Spremnik vode veličine je 4 l.',
                detalji: {
                    'RADNI TLAK (bar)': '30 – 150',
                    'SNAGA KW': '3 - 3.8',
                    'TEŽINA KG': '150 (s kolutom: 171)',
                    'MAX PROTOK VODE l / h': '720',
                    'MAX TEMPERATURA VODE °C': '140'
                }
            },
            { 
                naziv: 'Visokotlačni perač Therm 875-1',
                id: 24,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-therm-895.jpg'),
                brand: 'KRÄNZLE',
                opis: 'Therm 1 serija uključuje deset modela visokotlačnih uređaja za pranje na toplu vodu namijenjenih profesionalnim korisnicima koji predstavljaju najnaprednije uređaje u Kränzleovoj ponudi. Za therm 1 uređaje može se reći da su u svojoj kategoriji među najboljima u svijetu. Uređaji dolaze u izvedbi sa i bez koluta za namotavanje visokotlačnog crijeva. Imaju sporohodne motore (1400 Rpm) i namijenjeni su dugotrajnoj, svakodnevnoj uporabi u poljoprivredi, industriji, autopraonicama, prijevozničkim tvrtkama, građevini, turizmu i sl. Ističu se vrhunskim sigurnosnim karakteristikama kao i iznimnim user-friendly softverom. Total-stop system gasi elektro-motor i visokotlačnu pumpu uređaja u trenutku kada se pištolj otpusti. Kada se pištolj opet pritisne, elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Na ovaj način motor i pumpa rade samo onda kada se uređaj stvarno koristi. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval. Integrirani spremnik vode s plovkom uz Total-stop system osigurava maksimalnu zaštitu uređaja od problema s nedostatkom vode. Spremnik vode veličine je 4 l. Therm 1 serija ima mogućnost programiranja željenog načina pranja (željene temperature) te zaključavanja istog, kako bi se isti poslovi čišćenja uvijek izvodili na isti načina i s vodom jednake temperature.',
                detalji: {
                    'RADNI TLAK (bar)': '30 – 175',
                    'SNAGA KW': '4 - 4.8',
                    'TEŽINA KG': '212 (s kolutom: 218)',
                    'MAX PROTOK VODE l / h': '875',
                    'MAX TEMPERATURA VODE °C': '155'
                }
            },
            { 
                naziv: 'Visokotlačni perač Therm-RP',
                id: 25,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-therm-rp.jpg'),
                brand: 'KRÄNZLE',
                opis: 'Therm-RP serija uključuje osam modela visokotlačnih uređaja za pranje na toplu vodu namijenjenih profesionalnim korisnicima koji predstavljaju najnaprednije uređaje u Kränzleovoj ponudi. Za therm 1 uređaje može se reći da su u svojoj kategoriji među najboljima u svijetu. Uređaji dolaze u izvedbi sa i bez koluta za namotavanje visokotlačnog crijeva. Imaju sporohodne motore (1400 Rpm) i novu RP pumpu s radilicom, a namijenjeni su dugotrajnoj, svakodnevnoj uporabi u poljoprivredi, industriji, autopraonicama, prijevozničkim tvrtkama, građevini, turizmu i sl. Ističu se vrhunskim sigurnosnim karakteristikama kao i iznimnim user-friendly softverom. Total-stop system gasi elektro-motor i visokotlačnu pumpu uređaja u trenutku kada se pištolj otpusti. Kada se pištolj opet pritisne, elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Na ovaj način motor i pumpa rade samo onda kada se uređaj stvarno koristi. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval. Therm 1 serija ima mogućnost programiranja željenog načina pranja (željene temperature) te zaključavanja istog, kako bi se isti poslovi čišćenja uvijek izvodili na isti načina i s vodom jednake temperature.',
                detalji: {
                    'RADNI TLAK (bar)': '70 – 200',
                    'SNAGA KW': '6 - 8',
                    'TEŽINA KG': '222 (s kolutom: 228)',
                    'MAX PROTOK VODE l / h': '1000 - 1600',
                    'MAX TEMPERATURA VODE °C': '145 - 155'
                }
            },
            // { 
            //     naziv: 'Visokotlačni perač HDS 13/20-4 S',
            //     id: 26,
            //     kategorija: 'Toplovodni perači',
            //     picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-hds-s.jpg'),
            //     brand: 'KÄRCHER',
            //     opis: 'Više snage u super klasi Kärcher visokotlačnih čistača s vrućom vodom nije moguće: HDS 13/20-4 S je najsnažniji uređaj koji imamo u ponudi. Velika dobavna količina vode dozvoljava rad s dvije prskalice, što pojednostavljuje i ubrzava zahtjevni posao. Pri tome visokotlačni pištolj EASY!Force koristi reaktivnu silu visokotlačnog mlaza i tako korisniku smanjuje silu držanja na nulu. Zbog mnoštva inovativnih obilježja opreme, kao što su eco!efficiency stupanj, servisni prekidač za regulaciju tvrdoće vode, turbo puhalo, precizno doziranje sredstva za čišćenje, optimizirana tehnika plamenika, visok stupanj korisnosti pumpe i patentirane tehnologije mlaznica, uređaj je i vrlo učinkovit i vrlo uvjerljiv u rezultatima čišćenja. Dodatno obilježje je veliki LED zaslon, na kojemu je u svako doba moguće komforno očitati razine napunjenosti goriva, sredstva za čišćenje i postavke sustava. Također i jednostavno rukovanje, promišljeni koncept mobilnosti kao i jednostavan pristup svim relevantnim komponentama olakšavaju korisniku rukovanje, transport i servisiranje.',
            //     detalji: {
            //         'RADNI TLAK (bar)': '30 – 200',
            //         'SNAGA KW': '9,3',
            //         'TEŽINA KG': '188,5 (s ambalažom: 200,5)',
            //         'MAX PROTOK VODE l / h': '600 - 1300',
            //         'MAX TEMPERATURA VODE °C': '80 - 155'
            //     }
            // },
            // { 
            //     naziv: 'Visokotlačni perač HDS 10/20-4 MX',
            //     id: 27,
            //     kategorija: 'Toplovodni perači',
            //     picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-hds-mx.jpg'),
            //     brand: 'KÄRCHER',
            //     opis: 'Bez kompromisa u svim pitanjima: maksimalan tlak, maksimalna dobavna količina. Visokotlačni čistač s vrućom vodom HDS 10/20-4 MX, opremljen 4-polnim elektromotorom s vodenim hlađenjem i s ugrađenim bubnjem za namatanje crijeva s 20 metara visokotlačnog crijeva, najsnažniji je uređaj iz Kärcher srednje klase. Inovativni eco!efficiency stupanj kao i precizno doziranje sredstva za čišćenje i optimizirana tehnika plamenika smanjuju opterećenja za okoliš i za Vaš novčanik na minimum, dok patentirana tehnologija mlaznica, keramički klipovi, turbo puhalo kao i odličan stupanj učinkovitosti pumpe osiguravaju najbolje rezultate čišćenja. Optimalno je također i rukovanje, koje se odlikuje, primjerice, pločom s instrumentima kojom se rukuje intuitivno, LED prikazima kao i promišljenim konceptom mobilnosti, što izuzetno pojednostavljuje rukovanje uređajem i njegov transport. Osim toga, jednostavni su i svi radovi servisiranja i održavanja, budući da su sve relevantne komponente lako pristupačne, a podaci o radu mogu se uvijek praktično pozvati.',
            //     detalji: {
            //         'RADNI TLAK (bar)': '30 – 200',
            //         'SNAGA KW': '7,8',
            //         'TEŽINA KG': '172 (s ambalažom: 184)',
            //         'MAX PROTOK VODE l / h': '500 - 1000',
            //         'MAX TEMPERATURA VODE °C': '80 - 155'
            //     }
            // },
            // { 
            //     naziv: 'Visokotlačni perač HDS 7/16 C',
            //     id: 28,
            //     kategorija: 'Toplovodni perači',
            //     picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-hds-c.jpg'),
            //     brand: 'KÄRCHER',
            //     opis: 'Trofazni, snažni visokotlačni čistač s vrućom vodom HDS 7/16 C iz HDS-kompaktne klase posjeduje promišljeno rukovanje jednim gumbom kao i velike kotače i upravljački kotač, koji korisniku uvelike olakšavaju rukovanje i transport. Ovaj uređaj odlikuju proširena oprema i iznimno ekonomičan i ekološki rad zahvaljujući eco!efficiency stupnju. Dok EASY!Force visokotlačni pištolj iskorištava reaktivnu silu visokotlačnog mlaza kako bi silu držanja smanjio na nulu, EASY!Lock brzi zatvarači omogućuju pet puta brže rukovanje u usporedbi s običnim vijčanim spojevima, bez gubitka na robusnosti i dugovječnosti. Servo Control nudi kontinuiranu regulaciju tlaka i količine vode izravno na pištolju. Spremnici za sredstvo za čišćenje i gorivo su zaštićeno integrirani u otpornu šasiju, koja dopušta čak i primjene u težim uvjetima. Pribor, mlaznice i alati mogu se bez problema pospremiti u praktične pretince za odlaganje.',
            //     detalji: {
            //         'RADNI TLAK (bar)': '30 – 160',
            //         'SNAGA KW': '4,7',
            //         'TEŽINA KG': '105,8 (s ambalažom: 114,6)',
            //         'MAX PROTOK VODE l / h': '270 - 660',
            //         'MAX TEMPERATURA VODE °C': '80'
            //     }
            // },
            // { 
            //     naziv: 'Visokotlačni perač HDS 5/15 UX',
            //     id: 29,
            //     kategorija: 'Toplovodni perači',
            //     picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-hds-ux.jpg'),
            //     brand: 'KÄRCHER',
            //     opis: 'HDS 5/15 UX: Početnički uređaj u klasi visokotlačnih čistača s vrućom vodom uvjerava svojim promišljenim i inovativnim upright dizajnom. Ova vrsta horizontalne koncepcije uređaja ima za posljedicu malu težinu i vrlo kompaktne dimenzije. Tako je uređaj moguće vrlo jednostavno transportirati u osobnim kombi vozilima, a isto tako je i – zahvaljujući velikim kotačima i izvlačnoj ručki – vrlo mobilan na neravnoj podlozi. Vrlo visoki učinak čišćenja jamči usklađenost patentirane tehnologije mlaznica, turbo puhala i povećanog stupnja učinkovitosti pumpe. Komforan rad omogućuju EASY!Force visokotlačni pištolj, koji koristi reaktivnu silu visokotlačnog mlaza kako bi se sila držanja za korisnika smanjila na nulu, kao i EASY!Lock brzi zatvarači, pomoću kojih je pripremanje i pospremanje uređaja pet puta brže u odnosu na obične vijčane spojeve. Jednostavno rukovanje, mogućnosti odlaganja crijeva i prskalice kao i integrirani bubanj za crijevo s 15 metara visokotlačnog crijeva dopunjuju opsežnu opremu uređaja.',
            //     detalji: {
            //         'RADNI TLAK (bar)': '150',
            //         'SNAGA KW': '2,7',
            //         'TEŽINA KG': '80,5 (s ambalažom: 89,7)',
            //         'MAX PROTOK VODE l / h': '450',
            //         'MAX TEMPERATURA VODE °C': '80'
            //     }
            // },
            // { 
            //     naziv: 'Visokotlačni perač HDS 9/50 De Tr1',
            //     id: 30,
            //     kategorija: 'Toplovodni perači',
            //     picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-hds-prikolica.jpg'),
            //     brand: 'KÄRCHER',
            //     opis: 'HDS 9/50 proizvodi tlak od 500 bara, ubraja se u klasu visokotlačnih čistača s vrućom vodom na kolicima i pruža maksimalnu mobilnost. Zahvaljujući jakom dizelskom motoru, uređaj je potpuno neovisan od izvora električne energije na svim mogućim mjestima.',
            //     detalji: {
            //         'RADNI TLAK (bar)': '150 - 500',
            //         'MAX PROTOK VODE l / h': '500 - 900',
            //         'VRSTA MOTORA': 'Yanmar motor od 19 kW',
            //         'DIMENZIJE': '3646 x 1747 x 1735',
            //         'GORIVO': 'Diesel'
            //     }
            // },
            // { 
            //     naziv: 'Visokotlačni perač HDS 1000 De CEMO',
            //     id: 31,
            //     kategorija: 'Toplovodni perači',
            //     picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-hds-cemo.jpg'),
            //     brand: 'KÄRCHER',
            //     opis: 'Opremljen snažnim Yanmarovim dizelskim motorom i zaštićen čvrstim okvirom, naš visokotlačni čistač s vrućom vodom HDS 1000 De Steamer s integriranim stupnjem pare također impresionira tijekom teških primjena na mjestima gdje nema dostupnih vanjskih izvora energije. Jednostavna promjena iz visokotlačnog u parni način rada nudi i maksimalnu fleksibilnost. Veliki kapacitet plamenika i integrirana E-kutija s temperaturnim senzorom za stalno visoke temperature do 155 °C omogućuju ne samo uklanjanje korova, nego i smanjenje virusa i deaktiviranje bakterija i klica na površinama. Kvalitetne komponente, kao što su visokotlačna pumpa s mesinganom glavom cilindra, ventili od nehrđajućeg čelika ili klipovi od nehrđajućeg čelika presvučeni kromiranim niklom, osiguravaju izuzetno dug životni vijek, također tijekom teških, kontinuiranih primjena. Ploveći prekidač s integriranom zaštitom od kalcifikacije (DGT) dio je opsežne sigurnosne opreme dizajnirane za zaštitu korisnika i stroja. Pametni dodaci za uklanjanje korova vrućom vodom, kao i za primjenu pare u borbi protiv patogena, uključeni su kao standard.',
            //     detalji: {
            //         'RADNI TLAK (bar)': '40 - 200',
            //         'SPREMNIK ZA GORIVO (L)': '34',
            //         'TEŽINA KG': '188,3 (s ambalažom: 194,1)',
            //         'MAX PROTOK VODE l / h': '450 - 900',
            //         'MAX TEMPERATURA VODE °C': '80 - 155'
            //     }
            // },
            { 
                naziv: 'Visokotlačni perač AR 4590',
                id: 32,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-ar-4590.jpg'),
                brand: 'ANNOVI REVERBERI',
                opis: 'Trožilna pumpa sa keramičkim klipovima, štapni sistem spajanja. Paljenje grijača sa nekoliko sekundi odgode od uključenja perača. Kod pomanjkanja vode sigurnosni prekidač pritiska isključuje grijač. Spiralna funkcija hlađenja pokreće se neovisnim elektromotorom. Profesionalna dvocijevna diesel pumpa. Dovodni strujni kabel u dužini 5m i spremnik za gorivo 14l. Visokotlačno crijevo, pištolj i lepezasta mlaznica.',
                detalji: {
                    'SNAGA': '2,5kW - 230V',
                    'RADNI TLAK (bar)': '150',
                    'TEMPERATURA °C': '90',
                    'TEŽINA KG': '48',
                    'MAX PROTOK VODE l / h': '500',
                }
            },
            { 
                naziv: 'Visokotlačni perač AR 6650',
                id: 33,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-ar-6650.jpg'),
                brand: 'ANNOVI REVERBERI',
                opis: 'Trožilna pumpa sa keramičkim klipovima, štapni sistem spajanja. TSS prekidač za totalno isključivanje visokotlačnog perača. Ugrađen regulator za pritisak. Gašenje grijača sa nekoliko sekundi odgode. Kod pomanjkanja vode sigurnosni prekidač pritiska isključuje grijač. Profesionalna dvocijevna diesel pumpa. Dovodni strujni kabel u dužini 5m. Regulator na glavi za visoki/niski pritisak, lepezasta mlaznica za raspršivanje.',
                detalji: {
                    'SNAGA': '4,0kW - 400V',
                    'RADNI TLAK (bar)': '150',
                    'TEMPERATURA °C': '30 - 150',
                    'TEŽINA KG': '82',
                    'MAX PROTOK VODE l / h': '600',
                }
            },
            { 
                naziv: 'Visokotlačni perač AR 7870',
                id: 34,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-ar-7870.jpg'),
                brand: 'ANNOVI REVERBERI',
                opis: 'Trožilna pumpa sa keramičkim klipovima, štapni sistem spajanja. TSS prekidač za totalno isključivanje visokotlačnog perača. Ugrađen regulator za pritisak. Paljenje grijača sa nekoliko sekundi odgode od uključenja perača. Kod pomanjkanja vode sigurnosni prekidač pritiska isključuje grijač. Profesionalna dvocijevna diesel pumpa. Dovodni strujni kabel u dužini 10m. Regulator na glavi za visoki/niski pritisak, lepezasta mlaznica za raspršivanje.',
                detalji: {
                    'SNAGA': '7,4kW – 400V',
                    'RADNI TLAK (bar)': '200',
                    'TEMPERATURA °C': '30 - 150',
                    'TEŽINA KG': '98',
                    'MAX PROTOK VODE l / h': '900',
                }
            },
            { 
                naziv: 'Visokotlačni perač SUSETTE 150',
                id: 35,
                kategorija: 'Toplovodni perači',
                picture: require('@/assets/pictures/toplovodni_peraci/visokotlacni-susette.jpg'),
                brand: 'CLEANFIX',
                opis: 'ACDS: Napredna kontrola sa odloženim zaustavljanjem. Triplex klipna pumpa s tri keramička klipa i mesingan-om glavom. Zaštita spoja motora / pumpe s posebnim sustavom zadržavanja maziva. Pumpe nove generacije, visoka učinkovitost i malo održavanja. Automatsko isključivanje u praznom hodu duže od 20 min. Dva kruta stražnja kotača i dva prednja okretna kotača s kočnicama. LED za upozorenje “stroj je spreman” i “neispravnost”.',
                detalji: {
                    'SNAGA KW': '4,7',
                    'RADNI TLAK (bar)': '150',
                    'NAPAJANJE': 'trofazno',
                    'MAX PROTOK VODE l / h': '900',
                }
            },
//----------------------------------------HLADNOVODNI PERAČI -------------------------------------------------
            { 
                naziv: 'Visokotlačni strojevi za čišćenje',
                id: 36,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-frank.jpg'),
                brand: 'FRANK',
                opis: 'Voda plus visoki tlak su naročito učinkovita kombinacija u borbi protiv nečistoće i smeća. FRANK strojevi za pranje hladnom vodom, s pogonom na monofaznu ili trofaznu struju.',
                detalji: {
                    'RADNI TLAK (bar)': '115 - 270',
                    'SNAGA KW': '2,6 - 12,7',
                    'MAX PROTOK VODE l / h': '700 - 1440',
                }
            },
            { 
                naziv: 'Visokotlačni perač Premium MC 5M-200/1050FA 400V',
                id: 37,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-mc-5m.jpg'),
                brand: 'NILFISK',
                opis: 'MC 5M srednja klasa Nilfiskovih hladnovodnih visokotlačnih perača opremljena je NA5 pumpom s oscilacijskim diskom, 3 keramička klipa i pojačanom mjedenom glavom pumpe za uporabu do 4-5 sati dnevno. Sklop motorne pumpe napravljen je za intenzivniju uporabu s 1450 okr./min, smanjujući razinu buke. Dostupno kao model aktiviran tlakom ili protokom, sa ili bez kolotura. MC 5M linija pruža visoku razinu robusnosti, mobilnosti i jednostavnosti korištenja.',
                detalji: {
                    'RADNI TLAK (bar)': '200',
                    'SNAGA KW': '6,1',
                    'TEŽINA KG': '73',
                    'MAX PROTOK VODE l / h': '1050',
                    'MAX TEMPERATURA VODE °C': '60'
                }
            },
            { 
                naziv: 'Visokotlačni perač Premium 190-12 220V',
                id: 38,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-premium.jpg'),
                brand: 'NILFISK',
                opis: 'Robusni monofazni visokotlačni perač namijenjen za manje zahtjevne dnevne zadatke čišćenja koji pruža profesionalni rezultat čišćenja. Dizajniran za dugotrajnost, opremljenjen je indukcijskim motorom, metalnim kućištem pumpe i mesing glavom pumpe. Uređaj pruža visoku razinu udobnosti u radu, fleksibilnost i jednostavnost upotrebe koju omogućava dugačko crijevo sa čeličnim opletom.',
                detalji: {
                    'RADNI TLAK (bar)': '190',
                    'SNAGA KW': '3,3',
                    'TEŽINA KG': '30,2',
                    'MAX PROTOK VODE l / h': '570 - 650',
                    'MAX TEMPERATURA VODE °C': '40'
                }
            },
            { 
                naziv: 'Visokotlačni perač RE 90',
                id: 39,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-re-90.jpg'),
                brand: 'STIHL',
                opis: 'Lagani i kompaktni visokotlačni perač s visokokvalitetnom opremom velikog radnog učinka. Aluminijsko tijelo pumpe, integrirani sustav podvozja/kotača, sustav raspršivanja sredstva za čišćenje, sapnice za pranje rotacijom tijela sapnice kao i lepezastim mlazom. s mogućnošću podešavanja pritiska sredstva u sustavu. Visokotlačno crijevo opremljeno je priključkom otpornim na uvijanje a time i sigurno od oštećenja crijeva zbog uzdužnog deformiranja. Upravljački dio sustava za pranje sigurno je smješten u odgovarajuće ležište na tijelu uređaja, siguran od slučajne aktivacije. VT crijevo je 6 m duljine, a uređaj je opremljen s transportnim ručkama radi lakšeg utovara i prijevoza. ',
                detalji: {
                    'RADNI TLAK (bar)': '10 - 100',
                    'SNAGA KW': '1,8',
                    'TEŽINA KG': '9,8',
                    'MAX PROTOK VODE l / h': '348',
                    'MAX TEMPERATURA VODE °C': '40'
                }
            },
            { 
                naziv: 'Visokotlačni perač RE 110',
                id: 40,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-re-110.jpg'),
                brand: 'STIHL',
                opis: 'Kompaktni i robusni perač s bogatom opremom i visokom kvalitetom izrade.  Neke od značajki: aluminijsko tijelo pumpe, elektromotor bez četkica, integrirano podvozje, set za raspršivanje sredstva za čišćenje, rotaciona i lepezasta sapnica s podešavanjem pritiska, Anti-Drill brza spojka crijeva, ležište VT pištolja za odlaganje tijekom pauzi i pri skladištenju, 7 m VT crijeva, transportna ručka. Dodajmo tome još postrani spremnik za opremu i sapnice.',
                detalji: {
                    'RADNI TLAK (bar)': '10 - 110',
                    'SNAGA KW': '1,7',
                    'TEŽINA KG': '17,6',
                    'MAX PROTOK VODE l / h': '440',
                    'MAX TEMPERATURA VODE °C': '40'
                }
            },
            { 
                naziv: 'Visokotlačni perač RE 130 plus',
                id: 41,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-re-130-plus.jpg'),
                brand: 'STIHL',
                opis: 'Snažni visokotlačni perač s dodatkom za udobniji rad. Kompaktni i robusni perač s bogatom opremom i visokom kvalitetom izrade.  Neke od značajki: aluminijsko tijelo pumpe, elektromotor bez četkica, integrirano podvozje, set za raspršivanje sredstva za čišćenje, rotaciona i lepezasta sapnica s podešavanjem pritiska, Anti-Drill brza spojka crijeva na pištolju i na peraču, ležište VT pištolja za odlaganje tijekom pauzi i pri skladištenju, 8 m trajnog i kvalitetnog VT crijeva, pojačana aluminijska transportna ručka. Dodajmo k tome i posebno dugu VT cijev i pištolj s mekom gumiranom ručkom za udobniji rad, bubanj za namatanje VT crijeva, aluminijsku teleskopsku ručku, postrani spremnik za opremu i ležište za sigurno i uredno odlaganje kabla za napajanje.',
                detalji: {
                    'RADNI TLAK (bar)': '10 - 135',
                    'SNAGA KW': '2,3',
                    'TEŽINA KG': '21,2',
                    'MAX PROTOK VODE l / h': '500',
                    'MAX TEMPERATURA VODE °C': '40'
                }
            },
            { 
                naziv: 'Visokotlačni perač RE 150 plus',
                id: 42,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-re-150-plus.jpg'),
                brand: 'STIHL',
                opis: 'Namijenjen profesionalnim korisnicima, te privatnicima s povećanim potrebama za korištenje. Snažan i dugovječan visokotlačni perač radnog tlaka od 140 bara, u PLUS izvedbi radi veće radne udobnosti. Kotur za namatanje visokotlačnog crijeva s vodilicom za ravnomjerno namatanje, teleskopska ručka, VT crijevo s čeličnim opletom duljine 12 m, mesingano kućište pumpe, mogućnost podešavanja radnog tlaka kao i udjela deterdženta u tekućini za pranje. Nadalje: integrirani spremnik za deterdžent s mogućnošću doziranja, podesiva sapnica lepezastog mlaza, rotaciona sapnica s mlaznicom od keramike otporne na habanje, brza spojka VT crijeva i pištolja u izvedbi protiv zakretanja VT crijeva, te naposljetku prostor za sigurno spremanje sapnica u uređaju.',
                detalji: {
                    'RADNI TLAK (bar)': '140',
                    'SNAGA KW': '2,9',
                    'TEŽINA KG': '31',
                    'NAPON V': '230',
                    'MAX PROTOK VODE l / h': '540',
                    'MAX TEMPERATURA VODE °C': '60'
                }
            },
            { 
                naziv: 'Visokotlačni perač RE 362',
                id: 43,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-re-362.jpg'),
                brand: 'STIHL',
                opis: 'Robustan dizajn i motor niskog broj okretaja za dug radni vijek. Dva spremnika za sredstva za čišćenje s mogućnošću odabira sredstva zakretanjem jednog prekidača. Inovativna utičnica naponskog kabla. Sklopiva ručka za lakši transport i skladištenje. Radni tlak i udio sredstva za čišćenje podesivi, ugrađen manometar.',
                detalji: {
                    'RADNI TLAK (bar)': '35 - 180',
                    'SNAGA KW': '6,5',
                    'TEŽINA KG': '72',
                    'MAX PROTOK VODE l / h': '1080',
                    'MAX TEMPERATURA VODE °C': '60'
                }
            },
            { 
                naziv: 'Visokotlačni perač HD 10/122 TS',
                id: 44,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-HD7.jpg'),
                brand: 'KRÄNZLE',
                opis: 'HD serija predstavlja odlično rješenje za korisnike koji će visokotlačni uređaj za pranje koristiti za manje zahtjevna čišćenja kuće, okućnice, radionice, garaže, za pranje automobila i sl. Uređaji su prenosivi i zauzimaju malo mjesta što olakšava skladištenje. Jednostavnost, učinkovitost i odličan omjer cijene i kvalitete čine HD seriju odličnim izborom za korisnike koji traže vrhunsku kvalitetu po prihvatljivoj cijeni. Total-stop system gasi elektro-motor i visokotlačnu pumpu uređaja u trenutku kada se pištolj otpusti. Kada se pištolj opet pritisne elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Na ovaj način motor i pumpa rade samo onda kada se uređaj stvarno koristi. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval.',
                detalji: {
                    'RADNI TLAK (bar)': '30 - 120',
                    'SNAGA KW': '1.8 - 2.5',
                    'TEŽINA KG': '23',
                    'MAX PROTOK VODE l / h': '600',
                }
            },
            { 
                naziv: 'Visokotlačni perač K 2195 TST',
                id: 45,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-k-2175.png'),
                brand: 'KRÄNZLE',
                opis: 'K 2160 serija objedinjuje nekoliko modela visoko profesionalnih uređaja za pranje sa sporohodnim motorima (1400 Rpm) kompaktnih dimenzija i jednostavnih za manipulaciju i skladištenje. Namijenjena je profesionalcima za zahtjevna čišćenja u autopraonicama, prijevozničkim tvrtkama, radionicama, industriji hrane, turizmu i sl. Zbog svojih praktičnih dimenzija i jednostavnog skladištenja može se koristiti i u privatnim kućanstvima. Total-stop system gasi elektro-motor i visokotlačnu pumpu uređaja u trenutku kada se pištolj otpusti. Kada se pištolj opet pritisne elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Na ovaj način motor i pumpa rade samo onda kada se uređaj stvarno koristi. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval. Vario-Jet mlaznica omogućava rad uređaja na niskom ili na visokom tlaku podešavanjem na samoj mlaznici bez potrebe za podešavanjem regulatora tlaka. Također postavljanjem Vario-Jet mlaznice u otvoreni položaj omogućava se disperzija kemikalije za pranje izravno preko mlaznice.',
                detalji: {
                    'RADNI TLAK (bar)': '30 - 180',
                    'SNAGA KW': '2.4 - 3.2',
                    'TEŽINA KG': '37',
                    'MAX PROTOK VODE l / h': '480',
                }
            },
            { 
                naziv: 'Visokotlačni perač WS-RP',
                id: 46,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-WS-RP.jpg'),
                brand: 'KRÄNZLE',
                opis: 'WS-RP serija donosi četiri stacionarna uređaja s novom Kränzle pumpom s radilicom (RP pumpa). WS-RP serija uređaja s novom pumpom razvijena je za najzahtjevnije aplikacije u prehrambenoj industriji, poljoprivredi, stočarstvu, industriji i građevini. Nova RP pumpa omogućuje visoki radni tlak s minimalnim protokom vode od 1000 l/h što garantira vrhunske rezultate čišćenja uz maksimalnu efikasnost. Nova tehnologija Kränzle RP pumpe omogućava vrhunske performanse uz iznimno jednostavno održavanje. Uređaji LX-RP serije nose oznake 1000, 1200, 1400 i 1600 što ujedno označava i njihove performanse, odnosno protok vode u satu, pa tako najsnažniji WS-RP ima protok vode od 1600 l/h. Total-stop system s odgođenim gašenjem motora gasi elektro-motor i visokotlačnu pumpu uređaja 30 sekundi nakon što se pištolj otpusti. Kada se pištolj opet pritisne elektro-motor i pumpa se pale i može se nastaviti rad s uređajem. Tako se smanjuje pritisak na motor i pumpu, štedi se energija i povećava servisni interval. Također, u slučaju da se uređaj po završetku rada zaboravi ugasiti on se sam ugasi nakon 20 min mirovanja.',
                detalji: {
                    'RADNI TLAK (bar)': '30 - 200',
                    'SNAGA KW': '6 - 8',
                    'TEŽINA KG': '62',
                    'MAX PROTOK VODE l / h': '1000 - 1600',
                }
            },
            { 
                naziv: 'Visokotlačni perač B 240 T',
                id: 47,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-B.png'),
                brand: 'KRÄNZLE',
                opis: 'B serija donosi četiri uređaja na motorni pogon koji predstavljaju vrhunac Kränzleove ponude. Uređaji su namjenjeni korisnicima koji čišćenja obavljaju na prostoru gdje nije dostupna električna energija. Kränzle u ovom dijelu asortimana ima partnerstvo s vodećim svjetskim proizvođačem motora Hondom te u sve svoje uređaje ugrađuje Honda GX motore. Kränzle motorni uređaji namijenjeni su profesionalnim korisnicima u poljoprivredi, građevini, industriji, radionicama, prijevozničkim tvrtkama i sl. Posebno dobre rezultate ostvaruje u građevini zbog čega je na uređaj ugrađena i kuka za kran s kojom je omogućeno podizanje uređaja na visinu (na skelu i sl.). Turbokiller rotacijska mlaznica najveća je rotacijska mlaznica u Kränzle ponudi. Koriste ju najsnažniji uređaji s visokim tlakom i velikim protokom vode. Mlaznica omogućava vodi da iz cijevi izlazi u rotacijskom – kružnom mlazu čime se snaga čišćenja povećava za 20%. Koristi se za uklanjanje najtežih zaprljanja gdje klasična ravna mlaznica nije dovoljna. Ravna regulirajuća mlaznica omogućava kontrolu tlaka vode regulacijom na samoj mlaznici bez potrebe za pomicanjem regulatora tlaka.',
                detalji: {
                    'RADNI TLAK (bar)': '30 - 220',
                    'MOTOR': 'Honda GX340 LX',
                    'TEŽINA KG': '93',
                    'MAX PROTOK VODE l / h': '960',
                }
            },
            { 
                naziv: 'Visokotlačni perač AR 143',
                id: 48,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-ar-143.png'),
                brand: 'ANNOVI REVERBERI',
                opis: 'AR 143 visokotlačni perač s aluminijskom pumpom max.snage 120 bara i protokom do 390 l/h je učinkovit uređaj za razne potrebe čišćenja, a opremljen je sa 2 mlaznice (lepeza i rotirajuća). Atraktivno kućište i mala težina čine ga odličnim izborom za povremeno pranje auta, skutera, terase, opreme i strojeva, itd.',
                detalji: {
                    'SNAGA': '1,5kW - 230V',
                    'RADNI TLAK (bar)': '120',
                    'TEMPERATURA °C': '50',
                    'TEŽINA KG': '6',
                    'MAX PROTOK VODE l / h': '390',
                }
            },
            { 
                naziv: 'Visokotlačni perač AR 491',
                id: 49,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-ar-491.jpg'),
                brand: 'ANNOVI REVERBERI',
                opis: 'Mobilno kučište peača na dva kotača, ručica i bubanj/motalica za namatanje crijeva. Pumpa sa promjenljivim 3-osovinskom klipovima. Automatski sigurnosni ventili sa isključivanjem pritiska na glavi pumpe. Dovodni strujni kabel u dužini 6m. Integrirana posuda za šampon u kučištu perača. Visokotlačno gumirano crijevo u dužini 8m, pištolj i 2 mlaznice (ravna i rotirajuća). Glava opremljena sa sklopom za regulaciju prskanja.',
                detalji: {
                    'SNAGA': '2,1kW - 230V',
                    'RADNI TLAK (bar)': '145',
                    'TEMPERATURA °C': '50',
                    'TEŽINA KG': '17',
                    'MAX PROTOK VODE l / h': '450',
                }
            },
            { 
                naziv: 'Visokotlačni perač AR 747',
                id: 50,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-ar-747.jpg'),
                brand: 'ANNOVI REVERBERI',
                opis: 'Mobilno kučište peača na dva kotača, ručica i bubanj/motalica za namatanje crijeva. Pumpa sa promjenljivim 3-osovinskom klipovima. Automatski sigurnosni ventili sa isključivanjem pritiska na glavi pumpe. Dovodni strujni kabel u dužini 6m. Integrirana posuda za šampon u kučištu perača. Visokotlačno gumirano crijevo u dužini 8m, pištolj i 2 mlaznice (ravna i rotirajuća). Glava opremljena sa sklopom za regulaciju prskanja.',
                detalji: {
                    'SNAGA': '3.1kW - 230V',
                    'RADNI TLAK (bar)': '160',
                    'TEMPERATURA °C': '60',
                    'TEŽINA KG': '32',
                    'MAX PROTOK VODE l / h': '540',
                }
            },
            { 
                naziv: 'Visokotlačni perač AR 955',
                id: 51,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-ar-955.jpg'),
                brand: 'ANNOVI REVERBERI',
                opis: 'Kolica na koja su montirana dva kotača, sa ručicom i spremnikom za deterdžent. Visokoprofesionalna klipna pumpa sa keramičkim klipovima, sistem štapnog spajanja - automatski propusni ventil. Dovodni strujni kabel u dužini 5m i boca za deterdžent kapaciteta 10l. Regulator na glavi za visoki / niski pritisak, lepezasta/kitnjasta mlaznica za raspršivanje.',
                detalji: {
                    'SNAGA': '9,0kW - 400V',
                    'RADNI TLAK (bar)': '200',
                    'TEMPERATURA °C': '60',
                    'TEŽINA KG': '63',
                    'MAX PROTOK VODE l / h': '900',
                }
            },
            { 
                naziv: 'Visokotlačni perač MAXIMA 200',
                id: 52,
                kategorija: 'Hladnovodni perači',
                picture: require('@/assets/pictures/hladnovodni_peraci/visokotlacni-maxima.jpg'),
                brand: 'CLEANFIX',
                opis: 'Pumpa s keramičkim klipovima i mesinganom glavom. Sustav zaštite spojnica vratila za pumpe motora (SJ) za modele 130/10 i 160/15. Napredno upravljanje strojevima (ACDS) za modele od 180 do 350 bara. Automatsko isključivanje zbog nedostatka uporabe nakon 20 min (ACDS) za modele od 180 do 350 bara. LED za upozorenje “spreman za rad” i “greška” (ACDS) za modele od 180 do 350 bara. Skladištenje vremena korištenja stroja s LED signalom kada je postignuto programirano razdoblje održavanja (ACDS) za modele od 180 do 350 bara.',
                detalji: {
                    'SNAGA KW': '8,4',
                    'RADNI TLAK (bar)': '200',
                    'NAPAJANJE V': '380',
                    'MAX PROTOK VODE l / h': '1200',
                }
            },
//----------------------------------------PERAČI PODOVA -------------------------------------------------
            { 
                naziv: 'Uređaj za pranje i usisavanje Nilfisk SC530',
                id: 53,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-sc530.jpg'),
                brand: 'NILFISK',
                opis: 'Uređaj za pranje i čišćenje sa vlastitim pogonom, vrlo ekonomičan, pouzdan i praktičan za rad u objektima kao što su škole,vrtići,bolnice,garaže,marketi,javne zgrade i sl. Zbog spremnika od 61 litre smanjuje vrijeme čišćenja i povećava produktivnost rada. Spremnik otpadne tekućine se jednostavno preklapa pa na taj nasčin omogućava jednostavan pristup baterijama.',
                detalji: {
                    'SNAGA W': '950',
                    'RAZINA BUKE dB': '69',
                    'DIMENZIJE': '1260 x 550 x 1080',
                    'TEŽINA KG': '233',
                }
            },
            { 
                naziv: 'Kombinirani uređaj CS7010',
                id: 54,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-cs.jpg'),
                brand: 'NILFISK',
                opis: 'Kombinirani uređaj za metenje, pranje i usisavanje podova. CS7010 iznova osmišljava novu klasu opreme smanjujuću ukupnu vrijednost vlasništva (koja uključuje vlasništvo, rad sa uređajem i održavanje), a istodobno povećava ekonomičnost potrošnje goriva i učinkovitost izvođenja.',
                detalji: {
                    'SPREMNIK VODE L': '284',
                    'RAZINA BUKE dB': '72',
                    'DIMENZIJE': '2620 x 1650 x 1500 ',
                    'TEŽINA KG': '2593',
                }
            },
            { 
                naziv: 'Uređaj za pranje i usisavanje SC3500',
                id: 55,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-sc3500.jpg'),
                brand: 'NILFISK',
                opis: 'Ako vam trebaju velike performanse u zatvorenom prostoru, a uz ekonomično korištenje onda je SC3500 Vaše idealno rješenje. Uz poboljšanja produktivnosti, upravljivosti i ergonomiju, ovaj svestrani uređaj Nilfisk GO-Line može se s lakoćom koristiti u trgovačkim centrima, obrazovnom ili industrijskim okruženju i sl.',
                detalji: {
                    'SNAGA W': '2658',
                    'RAZINA BUKE dB': '69',
                    'DIMENZIJE': '1620 x 760 x 1250',
                    'TEŽINA KG': '230',
                    'MAX BRZINA KM/H': '6',
                    'NAPAJANJE V': '24'
                }
            },
            { 
                naziv: 'Uređaj za pranje i usisavanje SC351 Full Package',
                id: 56,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-sc351.jpg'),
                brand: 'NILFISK',
                opis: 'Sastav uređaja: element za sušenje poda sa gumom usisa, četka 370 mm prolene blue, baterija 12 V 55 Ah AGM SPIRACEL, ugradbeni punjač 12V 15A 230V EU KIT.',
                detalji: {
                    'SNAGA W': '450',
                    'DIMENZIJE': '730 x 475 x 450',
                    'TEŽINA KG': '73',
                    'NAPON V': '12'
                }
            },
            { 
                naziv: 'Uređaj za pranje i usisavanje SC250 34C B FULL PACK',
                id: 57,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-sc250.jpg'),
                brand: 'NILFISK',
                opis: 'SC250 je kompaktan uređaj za brzo i učinkovito čišćenje tvrdih podloga koji može prići u svaki kutak i osigurati učinkovito čišćenje u oba snjera. Vrlo je pogodan za korištenje jer može očistiti mjesta ispod namještaja,polica i sl. Zbog niske razine buke pogodan je za čišćenje tokom dana u prostorijama kao što su škole, bolnice,restorani,trgovine,kafići,pekare,hoteli i sl. Prljavština i prašina se vlo jednostavno uklanjaju zahvaljujući 34 cm cilindričnoj četki, a uređaj je jedinstven po tome što čisti i usisava neovisno o smjeru kretanja. 6 litarski spremnik svježe vode osigurava uređaju neprekidan rad u trajanju 40 minuta bez punjenja. Uređaj pokreće jaka 36V litijska baterija. Ergonomska ručka se može podesiti u više položaja pa je stoga uređaj vrlo jednostavan za korištenje. One touch tipka koja se nalazi na ručki upravljanja aktivira istovremeno sve bitne funkcije.',
                detalji: {
                    'SNAGA W': '300',
                    'RAZINA BUKE dB': '66',
                    'DIMENZIJE': '630 x 425 x 400',
                    'TEŽINA KG': '32',
                    'SPREMNIK VODE L': '6',
                }
            },
            { 
                naziv: 'Uređaj za pranje i usisavanje SC2000 komplet opremljen',
                id: 58,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-SC2000.jpg'),
                brand: 'NILFISK',
                opis: 'Oprema uređaja: četka 530 mm prolene blue, baterije 12 V 105 Ah GEL MONOBLOCK, ugradbeni punjač 24V 100-230V EU, crijevo za ulijevanje svježe vode, servisni ključ i ključ za operatera, ecoflex sistem za kemiju, element za sušenje poda',
                detalji: {
                    'RAZINA BUKE dB': '68/62 ±3',
                    'DIMENZIJE': '1270 x 550 x 1020',
                    'TEŽINA KG': '342',
                    'NAPAJANJE': '24',
                }
            },
            // { 
            //     naziv: 'SCRUBMASTER B70 CL, stroj za pranje',
            //     id: 59,
            //     kategorija: 'Perači podova',
            //     picture: require('@/assets/pictures/peraci_podova/peraci-scrubmaster-b70-cl.jpg'),
            //     brand: 'HAKO',
            //     opis: 'Kombinirani stroj sa upravljanjem i izvrsnom sposobnošću čišćenja. Nudi znatno bolju manevarsku sposobnost, izvrstan pregled na radnu površinu i veći prostor za noge pri gibanju stroja u smjeru naprijed. Robustne glave za četke izrađene iz korozijsko otporne aluminijske legure glede na izbor. Kružne četke promjera 65 cm-120 cm (sa dodatnim uključivanjem povećanja pritiska čaetki na čišćenu površinu. Dva ugrađena snažna elektromotora osiguravaju jednakomjerne rezultate čišćenja po cijeloj širini. Radi relativno male visine zaštite četki za pranje (ispod 10 cm) predstavlja idealnu visinu za čišćenje površina ispod pokućstva. Ugrađen sistem za direktno izbacivanje četki -BRS (Brush Release System) koji omogućava brzu zamjenu četki. Ugrađeni sistem Hako - Aqua - Control - System ACS -omogućava smanjenje potrošnje sredstva za čišćenje i s time posljedično i troškove čišćenja. ',
            //     detalji: {
            //         'RADNA BRZINA KM/H': '5',
            //         'VRIJEME RADA': 'do 240 min',
            //         'TEŽINA KG': '170',
            //         'NAPON V': '24',
            //     }
            // },
            // { 
            //     naziv: 'SCRUBMASTER B75, stroj za pranje',
            //     id: 60,
            //     kategorija: 'Perači podova',
            //     picture: require('@/assets/pictures/peraci_podova/peraci-scrubmaster-b75.jpg'),
            //     brand: 'HAKO',
            //     opis: 'Kombinirani stroj sa upravljanjem i izvrsnom sposobnošću čišćenja. Stroj kpl sa block baterijama 4x 6V / 180Ah i on-board punjačem.',
            //     detalji: {
            //         'RADNA BRZINA KM/H': '5,5',
            //         'TEŽINA KG': '230',
            //         'NAPON V': '24',
            //         'RADNA ŠIRINA CM': '65'
            //     }
            // },
            // { 
            //     naziv: 'BABY 35 LITHIUM',
            //     id: 61,
            //     kategorija: 'Perači podova',
            //     picture: require('@/assets/pictures/peraci_podova/peraci-baby-35.jpg'),
            //     brand: 'ADIATEK',
            //     opis: 'Stroj koji istovremeno oriba površinu i pokupi prljavštinu, tako da su površine odmah prohodne. Ime stroja govori, da je stroj malen i istovremeno vrlo obećavajući, jer ima mnoge fukncije. Kada se baterija isprazni, čišćenje možete nastaviti dalje, spajanjem stroja na električnu mrežu, što također puni bateriju. Autonomija baterije 2h + kabel za napajanje. Baby se jednostavno složi, tako da ga možete prevoziti na drugu lokaciju bez problema i u vozilu.',
            //     detalji: {
            //         'RAZINA BUKE dB(A)': '66,8',
            //         'TEŽINA KG': '53',
            //         'RADNA ŠIRINA MM': '345'
            //     }
            // },
            // { 
            //     naziv: 'JADE 66 B',
            //     id: 62,
            //     kategorija: 'Perači podova',
            //     picture: require('@/assets/pictures/peraci_podova/peraci-jade-66-b.jpg'),
            //     brand: 'ADIATEK',
            //     opis: 'Brzo dostupan usisni fiter sa povećanim kapacitetom za svakodnevno čišćenje. Jednostavno upravljanje s pomoću ergonomične kontrolne ploče. Prekidač na ključ i serijski ugrađeni zaslon sa zaštitom i brojačem radnih sati. Kontrolirano kretanje omogućuje lakše korištenje stroja. Spremnik prljave vode sa zasunom pruža jednostavan dostup do baterija (4x6V/180Ah) i lakše održavanje stroja. Inovativni parabolični usisni nastavak sa brzom spojkom za jednostavnu montažu i demnotažu te odlično usisavanje prljave vode. Brzo dostupne upravljačke palice i uređaj za punjenje u priručnom dijelu.',
            //     detalji: {
            //         'RAZINA BUKE dB(A)': '68',
            //         'TEŽINA KG': '111',
            //         'RADNA ŠIRINA MM': '660'
            //     }
            // },
            // { 
            //     naziv: 'CORAL 65 M',
            //     id: 63,
            //     kategorija: 'Perači podova',
            //     picture: require('@/assets/pictures/peraci_podova/peraci-coral-65-m.jpg'),
            //     brand: 'ADIATEK',
            //     opis: 'Profesionalni stroj za čišćenje. Učinkovita, tiha, praktična izvedba stroja. Robustan, učinkovit te podesiv usisni nastavak. Brza (de)montaža i čišćenje usisnog nastavka, gdje su usisne gume upotrebljive četverostruko. S aluminijem zaštićena i plastificirana glava četki otporna na udarce. Autonomija baterije 3-4h. Udobno i ergonomsko vozačko sjedalo, omogućuje pregled rada. Jednostavno čišćenje usisnog filtera i spremnika s prljavom vodom.',
            //     detalji: {
            //         'TEŽINA KG': '238',
            //         'RADNA ŠIRINA MM': '660',
            //         'NAPON V': '24',
            //         'BRZINA KM/H': '5'
            //     }
            // },
            // { 
            //     naziv: 'GHIBLI ROLLY NRG, stroj za pranje',
            //     id: 64,
            //     kategorija: 'Perači podova',
            //     picture: require('@/assets/pictures/peraci_podova/peraci-rolly-nrg.jpg'),
            //     brand: 'GHIBLI',
            //     opis: 'Njegove dimenzije u kombinaciji s posebnom ručkom (patentirani) i okretni kotači omogućuju Rolly NRG rad u malim područjima. Litijske baterije: velika snaga u malom stroju. Valjkasta četka s tri različita pritiska prema dolje podesiva pritiskom na gumb, za čišćenje svake vrste prljavštine. Niska razina buke, pogodno za dnevno čišćenje i u svim okruženjima gdje je tišina je potrebna. Svatko može koristiti Rolly stroj, sve glavne funkcije mogu se upravljati upravljačkom pločom.',
            //     detalji: {
            //         'RAZINA BUKE dB(A)': '54',
            //         'TEŽINA KG': '34',
            //         'RADNA ŠIRINA MM': '330'
            //     }
            // },
            { 
                naziv: 'Stroj za pranje i usisavanje RA 431 E',
                id: 65,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-ra.jpg'),
                brand: 'CLEANFIX',
                opis: 'Uređaj namijenjen za čišćenje svih vrsta tvrdih podova srednje veličine u komercijalnim objektima, trgovinama, industrijskim pogonima,hotelima, gastronomiji… Temeljito čišćenje podova sa četkom i izvanredno usisavanje. Usisna grana sa uljno i kiselo otpornim trakama. Veliki pritisak četke omogućuje čišćenje najtvrdokornijih nečistoća. Za ergonomski rad podesiva ručka za upravljanje uređajem.',
                detalji: {
                    'NAPON V / Hz': '230 / 50',
                    'TEŽINA KG': '62',
                    'RADNA ŠIRINA CM': '43',
                    'DIMENZIJE': '80 x 43 x 73'
                }
            },
            { 
                naziv: 'Stroj za pranje i usisavanje RA 505 IBCT',
                id: 66,
                kategorija: 'Perači podova',
                picture: require('@/assets/pictures/peraci_podova/peraci-ra-505.jpg'),
                brand: 'CLEANFIX',
                opis: 'Integrirani punjač akumulatora.Automat za pranje podova nove generacije. Izniman kapacitet,ergonomija, aluminijsko kućište motora četke, optimalan pritisak četke samo su neke od osobina ovog modela.Uređaj je opremljen patentiranim S DOSE sustavom za puštanje tekućine kojim se regulira količina otopine za pranje poda ovisno o brzini rada.Kod sporije brzine rada pumpe ubrizgravaju manju količinu otopine za pranje poda čime postižemo manju potrošnju otopine. Namjena za srednje površine: javne ustanove, trgovački centri , industrijski pogoni…',
                detalji: {
                    'NAPON V DC': '24',
                    'TEŽINA KG': '178',
                    'RADNA ŠIRINA CM': '51',
                    'DIMENZIJE': '145 x 56 x 113'
                }
            },
//----------------------------------------USISAVAČI -------------------------------------------------
            { 
                naziv: 'Mokro-suhi usisavač SE 33',
                id: 67,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-se-33.jpg'),
                brand: 'STIHL',
                opis: 'Kompaktnim usisavačem SE 33 za mokro i suho čišćenje možete bez napora obavljati poslove čišćenja u kući, podrumu ili u automobilu. U tu svrhu, performanse i udobnost optimalno su kombinirani. Zahvaljujući visokoj izlaznoj snazi od 1,4 kW, SE 33 stvara dovoljan vakuum za širok raspon čišćenja. Pomoću usisnog crijeva duljine 2 m i dvodijelne usisne cijevi lako možete doći do teško dostupnih uglova, a da se pri tom ne morate pomicati. Mali koraci također ne predstavljaju problem za uređaj zbog rotirajućih prednjih kotača. Teško dostupnu prljavštinu i prašinu lako je ukloniti iz uglova ili ispod predmeta pomoću funkcije puhanja. Da biste to učinili, jednostavno priključite usisno crijevo na priključak za ulaz zraka. Veliku snagu usisavanja možete koristiti kako za mokro tako i za suho usisavanje te ove funkcije možete fleksibilno mijenjati  pomoću podesive mlaznice za pod. Kvalitetan pribor poput PET filtra, ravne mlaznice i podne mlaznice već su uključeni u standardnu opremu. Zahvaljujući kompaktnom dizajnu i integriranom skladištu dodatnog pribora, SE 33 usisavač za mokro / suho čišćenje može se skladištiti uz uštedu prostora.',
                detalji: {
                    'KOLIČINA USISANOG ZRAKA L / MIN': '3600',
                    'MAKSIMALNI PODTLAK MBAR': '210',
                    'TEŽINA KG': '5,4',
                    'ZAPREMNINA SPREMNIKA L': '12'
                }
            },
            { 
                naziv: 'Mokro-suhi usisavač SE 62',
                id: 68,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-se-62.jpg'),
                brand: 'STIHL',
                opis: 'Kompaktni usisavač za mokru i suhu nečistoću s kvalitetnom opremom. Funkcija ispuhivanja za čišćenje teško pristupačnih mjesta. Funkcija čišćenja pročistača, podesiva sapnica za usisavanje poda, sapnica za uska mjesta, univerzalna sapnica. Vrećice za usisavač od kvalitetnog flisa. Idealan za čišćenje radnih mjesta u kući, u podrumu, u neposrednoj blizini kuće i u malim radionicama.',
                detalji: {
                    'KOLIČINA USISANOG ZRAKA L / MIN': '3600',
                    'MAKSIMALNI PODTLAK MBAR': '210',
                    'TEŽINA KG': '7,5',
                    'ZAPREMNINA SPREMNIKA L': '20'
                }
            },
            // { 
            //     naziv: 'Leđni usisavač W1 BC LITHIUM SWIFT',
            //     id: 69,
            //     kategorija: 'Usisavači',
            //     picture: require('@/assets/pictures/usisavaci/usisavaci-w1.jpg'),
            //     brand: 'WIRBEL',
            //     opis: 'Kompaktan, lagan za rukovanje, leđni usisavač. Ruke su slobodne, pokreti neometani, rad olakšan, brži i učinkovitiji. Maximun zaštita i djelotvornost: (cartridge filter + tekstil filter bag + ispušni zrak i filteri za zaštitu motora). Držači pribora. W1 BC LITHIUM, sa SWIFT verzijom, predstavlja savršenu mješavinu između brzine i učinkovitosti zahvaljujući litijskim baterijama s trajanjem od 25 minuta. Baterije se mogu puniti u bilo kojem trenutku, čak i djelomično, bez rizika od oštećenja.',
            //     detalji: {
            //         'MAKSIMALNA SNAGA W': '330',
            //         'DIMENZIJE': '240 x 240 x 600',
            //         'TEŽINA KG': '7,3',
            //         'ZAPREMNINA SPREMNIKA L': '5'
            //     }
            // },
            { 
                naziv: 'Usisavač mokro suhi AERO 21-01',
                id: 70,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-aero-21.jpg'),
                brand: 'NILFISK',
                opis: 'AERO 21 linija suho mokrih usisavača stavlja fokus na područje primjene i zadovoljstvo krajnjih korisnika. Push&Clean sustav manualnog čišćenja filtera kao i funkcija ispuha nalazi se na svim modelima AERO linije. Spremnik veličine 20litara, mjesto za pohranu nastavaka za usisavanje kao i niska radna razina buke pruža veliku ergonomiju u radu.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '1250/1000',
                    'DIMENZIJE': '375 x 385 x 505',
                    'TEŽINA KG': '7,5',
                    'ZAPREMNINA SPREMNIKA L': '20',
                    'PROTOK ZRAKA L / MIN': '3600'
                }
            },
            { 
                naziv: 'Usisavač mokro suhi ATTIX 961-01',
                id: 71,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-attix.jpg'),
                brand: 'NILFISK',
                opis: 'Sa dva motora I INOX spremnikom. Industrijski mokro suhi usisavač profesionalne klase namijenjen za svakodnevno korištenje i u najtežim uvjetima. Idealan za korištenje u tvornicama, skladišnim prostorima kada treba brzo očistiti prostor. Zahvaljujući 38 mm standardnoj opremi Attix 961-01 je “Plug & Play” spreman zakorištenje. Opremljen je sa dva jaka motora. Perivi filter i njegova velika površina osiguravaju mu visoku učinkovitost sa malim troškovima održavanja. Spremnik je napravljen od nehrđajučeg čelika kapaciteta 70 litara te ga je moguće odvojiti ili nagnuti što ga čini jednostavnim za korištenje.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '2×1200',
                    'DIMENZIJE': '58 x 60 x 97',
                    'ZAPREMNINA SPREMNIKA L': '70',
                    'PROTOK ZRAKA L / MIN': '2x3500'
                }
            },
            { 
                naziv: 'Usisavač GU 455-DUAL-2 EU',
                id: 72,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-gu.jpg'),
                brand: 'NILFISK',
                opis: 'GU serija stojećih usisavača je dizajnirana za brzo,tiho i učinkovito čišćenje. Visok stupanj filtracija i opcija HEPA filter osiguravaju visoku kvalitetu zraka u zatvorenim prostorijama nakon upotrebe uređaja. Namijenjen za korištenje u hotelima, restoranima, barovima, bolnicama, staračkim domovima, stanovima. Također je pogodan za upotrebu ispod namještaja, stolova. Vrlo je jednostavan i praktičan za korištenje, a dizajniran je na način da može izdržati i veće udarce, a da mu vitalni dijelovi ne puknu. Opremljen je sa produženom cijevi za usisavanje tako da može usisati i teže dostupna mjesta. Četkice za usisavanje su podesive po visini tako da se usisavanje može prilagoditi raznim podlogama.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '1200',
                    'DIMENZIJE': '33 × 35,5 × 121',
                    'TEŽINA KG': '8,5',
                    'ZAPREMNINA SPREMNIKA L': '4',
                    'PROTOK ZRAKA L / SEC': '44'
                }
            },
            { 
                naziv: 'Usisavač T40PLUS L100 LC Z21',
                id: 73,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-t40plus.jpg'),
                brand: 'NILFISK',
                opis: 'Usisavač oblikovan i izrađen za komercijalnu upotrebu; za upotrebu u hotelima, školama, bolnicama, tvornicama, trgovinama, uredima, apartmanima i za najam; ne za uobičajenu upotrebu u domaćinstvima. Za skupljanje tvrdih i nezapaljivih materijala u zatvorenom prostoru i na otvorenom. UPOZORENJE – Usisavač je dozvoljeno primjenjivati samo za usisavanje suhih tvari. Obavezno osigurajte dovoljno prostora oko usisavača, tako da je nesmetan i jednostavan dostup do komandi. ',
                detalji: {
                    'MAKSIMALNA SNAGA KW': '7,5',
                    'TEŽINA KG': '24',
                    'ZAPREMNINA SPREMNIKA L': '50-100',
                    'PROTOK ZRAKA L / MIN': '5250'
                }
            },
            { 
                naziv: 'Usisavač CFM S2 L40 LC',
                id: 74,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-cfm.jpg'),
                brand: 'NILFISK',
                opis: 'Nova serija jednofaznih industrijskih usisavača S2 i S3 je zasnovana po praćenju tržišnih istraživanja izvedenih za pronalazak rješenja sadašnjih i budućih teškoća u industrijskom usisavanju za proizvodni svijet.Neke nove funkcije čine te usisavače jedinstvenima: elektronska nadzorna ploča omogućava jednostavnu upotrebu i stalan nadzor učinkovitosti usisavanja, omogućava i nadzor nekih opcijskih rješenja kao što su plovak tekućina ili tvrdih dijelića. Velika pozornost je bila namjenjena ergonomiji: novi jednofazni usisavači su lagani, jednostavni za upotrebu i za pomjeranje te osiguravaju sigurnu upotrebu. Na izbor su sa "L", "M" i "H" izvedbama filtera. Na izbor su u više od 90 varijanti, uključujući s certifikatima. Novi S2 i S3 su polazna točka za budućnost u tehnologiji industrijskog usisavanja. ',
                detalji: {
                    'MAKSIMALNA SNAGA KW': '2',
                    'TEŽINA KG': '80',
                    'ZAPREMNINA SPREMNIKA L': '40',
                    'PROTOK ZRAKA L / MIN': '4720'
                }
            },
            { 
                naziv: 'Usisavač T40W L100',
                id: 75,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-t40w.jpg'),
                brand: 'NILFISK',
                opis: 'Usisavač oblikovan i izrađen za komercijalnu upotrebu; u hotelima, apartmanima, školama, bolnicama, tvornicama, trgovinama, uredima. Čisti i usisava tvrde materijale u zatvorenim i na vanjskim prostorima. Oblikovan je tako, da ga može upotrebljavati istovremeno samo jedan korisnik. Usisavač čini automatska usisna jedinica sa gorivodnim filterom i posudom za skupljanje posisanog materijala. Opremljen s primarnim filterom, koji omogućuje različite načine upotrebe. ',
                detalji: {
                    'MAKSIMALNA SNAGA KW': '4',
                    'TEŽINA KG': '157',
                    'ZAPREMNINA SPREMNIKA L': '80',
                    'PROTOK ZRAKA L / MIN': '8600'
                }
            },
            { 
                naziv: 'Usisavač mokro suhi SW 21 Combi',
                id: 76,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-sw-21.jpg'),
                brand: 'CLEANFIX',
                opis: 'Robustan i fleksibilan, savršen model za mala i srednja područja. Za rukovatelje kojima je potreban mokri i suhi usisavač visokih performansi, s dodatnim prednostima za jednostavan rad.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '1100',
                    'TEŽINA KG': '12,7',
                    'ZAPREMNINA SPREMNIKA L': '16,5',
                    'DIMENZIJE': '42 x 42 x 65'
                }
            },
            { 
                naziv: 'Usisavač mokro suhi SW 25',
                id: 77,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-sw-25.jpg'),
                brand: 'CLEANFIX',
                opis: 'Svojim velikim usisnim kapacitetom SW 25 se profesionalno i pouzdano nosi s industrijskim površinama. Okvir za prevrtanje pojednostavljuje pražnjenje prljave vode i transport stepenicama gore-dolje.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '1100',
                    'TEŽINA KG': '12,3',
                    'ZAPREMNINA SPREMNIKA L': '20',
                    'DIMENZIJE': '60 x 40 x 84'
                }
            },
            { 
                naziv: 'RS08B Battery bežični suhi usisavač',
                id: 78,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-RS08B.jpg'),
                brand: 'CLEANFIX',
                opis: 'Idealno za čišćenje mjesta sa uskim ili zatvorenim prostorima ili prostorima poput kina, dvorana, kazališta, vlakova, autobusa i aviona. Također se može koristiti gdje nema utičnica. Savršeno za čišćenje stepenica i stubišta.  Upotreba litij-ionske baterije visoke učinkovitosti omogućava rad do 60 minuta. Mala nosiva težina od samo 6 kg (uključujući bateriju) omogućuje nesmetan i udoban rad.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '300',
                    'TEŽINA KG': '6',
                    'ZAPREMNINA SPREMNIKA L': '4,5',
                    'DIMENZIJE': '21 x 30 x 71'
                }
            },
            { 
                naziv: 'Suhi usisavač S 10 PLUS ECO',
                id: 79,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-eco.jpg'),
                brand: 'CLEANFIX',
                opis: 'Profesionalni usisivač koji postavlja nove standarde.Jednostavan za uporabu,kućište od visokokvalitetne ABS plastike. Pogodan za škole,bolnice,hotele,apartmane,urede. Izuzetno prilagođen modela sa ergonomskom ručkom i odvojivim kabelom. Model sa eco usisnim motorom.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '650',
                    'TEŽINA KG': '9,2',
                    'ZAPREMNINA SPREMNIKA L': '6',
                    'DIMENZIJE': '40 x 40 x 49'
                }
            },
            { 
                naziv: 'Suhi usisavač S20 PLUS',
                id: 80,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-s20.jpg'),
                brand: 'CLEANFIX',
                opis: 'Snažan, praktičan i robustan usisivač za velike zahtjeve. Izvrsna pokretljivost zahvaljujući maloj težini i 5 kotača.Volumen vrećice od 10 litara za dugotrajan rad.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '1100',
                    'TEŽINA KG': '10',
                    'ZAPREMNINA SPREMNIKA L': '10',
                    'DIMENZIJE': '38 x 38 x 59'
                }
            },
            { 
                naziv: 'Suhi mokri usisavač Ventos 30 E/L',
                id: 81,
                kategorija: 'Usisavači',
                picture: require('@/assets/pictures/usisavaci/usisavaci-ventos.png'),
                brand: 'KRÄNZLE',
                opis: 'Profesionalni usisavač za suho i mokro usisavanje. Kompaktnih dimenzija i jednostavan za prenošenje i skladištenje. Bogata serijska oprema. Širok izbor dodatne opreme. Idealan za kućanstvo, prijevozničke tvrtke, autopraonice, poljoprivredu i industriju.',
                detalji: {
                    'MAKSIMALNA SNAGA W': '2400',
                    'ZAPREMNINA SPREMNIKA L': '32',
                    'PRIKLJUČAK STRUJE': '230 V~1/50/60 Hz'
                }
            },
            // { 
            //     naziv: 'GARAGE EKSTRAKCIJA',
            //     id: 82,
            //     kategorija: 'Usisavači',
            //     picture: require('@/assets/pictures/usisavaci/usisavaci-ekstrakcija.jpg'),
            //     brand: 'BENT',
            //     opis: 'Kombinacija suhog usisavanja, ispiranja i skupljanja tekućina. Veća usisna snaga sa dva međusobno neovisna usisna motora. S robustnim inox rezervoarom na kolicima s velikim kotačima za jednostavan transport stroja. S tekstilnim filterom i mogučnošću uporabe vrećice za suho usisavanje. Veliki rezervoar za radnu otopinu omogućava dugu autonomiju rada. Širok asortiman pribora omogućava uporabu za razne namjene.',
            //     detalji: {
            //         'MAKSIMALNA SNAGA W': '2*1200',
            //         'PROTOK RADNE OTOPINE L / MIN': '0,8',
            //         'TEŽINA KG': '28,1'
            //     }
            // },
//----------------------------------------USLUGE -------------------------------------------------
            { 
                naziv: 'Staro za novo',
                id: 83,
                kategorija: 'Usluge',
                picture: require('@/assets/pictures/usluge/staro-za-novo.jpg'),
                brand: 'USLUGE',
                opis: 'Mijenjamo staro za novo.',
                detalji: {
                }
            },
            { 
                naziv: 'Staro za staro',
                id: 84,
                kategorija: 'Usluge',
                picture: require('@/assets/pictures/usluge/staro-za-staro.png'),
                brand: 'USLUGE',
                opis: 'Mijenjamo staro za staro.',
                detalji: {
                }
            },
            { 
                naziv: 'Proizvodnja visokotlačnih crijeva',
                id: 85,
                kategorija: 'Usluge',
                picture: require('@/assets/pictures/usluge/proizvodnja-crijeva.jpg'),
                brand: 'USLUGE',
                opis: 'Proizvodimo visokotlačna crijeva.',
                detalji: {
                }
            },
//----------------------------------------PRIBOR -------------------------------------------------
            { 
                naziv: 'Lanci za sve STIHL uređaje',
                id: 86,
                kategorija: 'Pribor',
                picture: require('@/assets/pictures/pribor/lanci.jpeg'),
                brand: 'PRIBOR',
                opis: 'U ponudi imamo lance za sve STIHL uređaje.',
                detalji: {
                }
            },
            { 
                naziv: 'Rotirajuće četke',
                id: 87,
                kategorija: 'Pribor',
                picture: require('@/assets/pictures/pribor/rotirajuce-cetke.jpeg'),
                brand: 'PRIBOR',
                opis: 'U ponudi imamo razne rotirajuće četke.',
                detalji: {
                }
            },
            { 
                naziv: 'Oprema za miniwash',
                id: 88,
                kategorija: 'Pribor',
                picture: require('@/assets/pictures/pribor/oprema-miniwash.jpeg'),
                brand: 'PRIBOR',
                opis: 'U ponudi imamo opremu za miniwash.',
                detalji: {
                }
            },
            { 
                naziv: 'Dizne',
                id: 89,
                kategorija: 'Pribor',
                picture: require('@/assets/pictures/pribor/dizna.jpeg'),
                brand: 'PRIBOR',
                opis: 'U ponudi imamo razne dizne.',
                detalji: {
                }
            },
        ],        
    },
    mutations: {
      
    },
    actions: {
      
    },
  
    modules: {
    },
    getters: {
        currentCategory: (state) => (ctg_id) => {
            return state.categories.filter(p => p.ctg_id === ctg_id)[0]
        },
        currentArticle: (state) => (id) => {
            return state.articles.filter(p => p.id === Number(id))[0]
        },
    }
})
  