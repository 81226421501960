<template>
  <div id="app"> 
    <div id="root">
      <div id="container" style="box-shadow: 0 0 5px 0 rgb(82, 81, 81);">
        <div id="contacts">
          <div id="contacts--social">
            <p>VRHUNSKA KVALITETA UZ POVOLJNE CIJENE</p>
          </div>
          <div id="contacts--contact">
            <h5><a style="color:rgb(94, 91, 91)" href="mailto:pecnik@pecnik.hr" target="_blank">pecnik@pecnik.hr</a></h5>
            <h5 style="color:rgb(94, 91, 91)">Tel: +38513388057</h5>
            <div v-if="$vuetify.breakpoint.width >= 660">
              <h5 style="color:rgb(94, 91, 91)">Mob: +385913388057, +385913388060</h5>
            </div>
            <div v-if="$vuetify.breakpoint.width < 660">
              <h5 style="color:rgb(94, 91, 91)">Mob: +385913388057,</h5>
              <h5 style="color:rgb(94, 91, 91)">+385913388060</h5>
            </div>
          </div>
        </div>
        <div id="mentions">
          <p>{{ new Date().getFullYear() }} — <strong>Pećnik</strong></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',

  data: () => {
    return {
      phone : '06.00.00.00.00',
      mention : '2018 | mentions légales'
    }
  }
}
</script>

<style>
.footer {
  background-color: rgba(94, 91, 91, 0.11);
  display: block;
  color: rgb(94, 91, 91);
  font-size: 17px;
  width: 100%;
}
#container{
  padding-top:10px;
  width:100vw;
  color:white;
  background-color:rgb(243, 243, 243);
  display:flex;
  flex-direction:column;
  align-items:center;
}
#contacts{
  display:flex;
  justify-content:space-around;
  align-items:center;
  width:100%;
  height: 60px;
}
#contacts--contact{
  width: 50%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
#contacts--contact h5{
  font-size: 13px;
  padding-top: 5px;
  text-decoration: none;
}
#contacts--social{
  width: 50%;
  margin-top: 20px;
  text-align: center;
}
#contacts--social p{
  font-size: 20px;
}
#mentions {
  padding-top: 30px;
}
#mentions p {
  font-size:11px;
}

@media screen and (max-width: 660px) {
  #contacts{
    margin-left: 5px;
    margin-right: 5px;
  }
  #contacts--social p{
    font-size: 15px;
  }
  #contacts--social{
    margin-top: 10px;
  }
  #contacts--contact{
    margin-top: 10px;
  }
  #contacts--contact h5{
    font-size: 11px;
    padding-top: 2px;
  }
  #mentions {
    padding-top: 20px;
  }
  #mentions p {
    font-size:8px;
  }
}
</style>
