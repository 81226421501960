<template>
    <v-app>
        <div>
            <Header/>
        </div>
        <main>
            <div class="container" v-if="$vuetify.breakpoint.width < 750">
                <v-row v-for="j in 4" :key="j">
                    <v-col
                        v-for="n in 2"
                        :key="n"
                    >
                    <router-link :to="{ name: 'ProductList', params: { categoryId: categories[2*j-n].ctg_id } }">
                        <v-card id="block" :elevation="10" outlined tile>
                            <v-img
                            id="images"
                            :src="pictures[2*j-n]"
                            aspect-ratio="0.75"
                            >
                            </v-img>
                            <div class="card-title align-end">
                                <v-card-title class="justify-center" style="letter-spacing: 2px; font-size: 16px; word-break: normal; color:white">{{categories[2*j-n].naziv}}</v-card-title>
                            </div>
                        </v-card>
                    </router-link>
                    </v-col>
                </v-row>
            </div>
            <div class="container" v-if="$vuetify.breakpoint.width >= 750">
                <v-row>
                    <v-col
                        v-for="n in categories.length"
                        :key="n"
                        cols="12"
                        sm="3"
                    >
                    <router-link :to="{ name: 'ProductList', params: { categoryId: categories[n-1].ctg_id }}">
                        <v-card id="block" :elevation="10" outlined tile>
                            <v-img
                            id="images"
                            :src="pictures[n-1]"
                            aspect-ratio="0.75"
                            >
                            </v-img>
                            <div class="card-title align-end">
                                <v-card-title class="justify-center" style="letter-spacing: 3px; font-size: 1.3rem; word-break: normal;color:white">{{categories[n-1].naziv}}</v-card-title>
                            </div>
                        </v-card>
                    </router-link>
                    </v-col>
                </v-row>
            </div>
        </main>
        <div>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
    import Header from '@/views/Header';
    import Footer from '@/views/Footer';
    
    export default {
        name: 'App',

        components: {
            Header,
            Footer
        },

        data: () => {
            return {
                categories: [],
                pictures: []
            }
        },
        created () {
            this.getCategories();
        },
        mounted() {
            this.getPictures();
        },
        methods: {
            getCategories() {
                this.categories = this.$store.state.categories
            },
            getPictures() {
                this.pictures = this.$store.state.pictures
            },
        }
    }
</script>

<style>
main {
    padding-top: 0.5rem;
    width: 100%;
}
.container {
    padding-right: .9375rem;
    padding-left: .9375rem;
    margin-right: auto;
    margin-left: auto;
}
.card-title {
    text-transform: uppercase;
    margin-bottom: .75rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background:rgba(0,0,0,0.75);
    box-sizing: inherit;
    position: absolute;
    bottom: 3rem;
    width: 100%;
    padding: 0.5rem;
}
#block {
    overflow: hidden;     
    display: block; 
}
#images:hover {
    transform: scale(1.2);
    transform-origin: 50% 50%;
    transition: all .4s linear;
}

@media screen and (max-width: 660px) {
    .card-title {
        margin-bottom: .75rem;
        padding: 0px;
        bottom: 1.7rem;
    }
}
</style>
